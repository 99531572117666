// import React from "react";
import { Outlet } from "react-router";
import Header from "./header";
// import Nav from "./nav";
// import Auth from "../common/Auth";

export default function RootLayout({userData, user}) {
  return (
    <div className="App route-layout">
      <Header userData={userData} user={user} />
      {/* <div className="App align-items-center"> */}
      <Outlet />
      {/* </div> */}
    </div>
  );
}
