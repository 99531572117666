import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import { AiOutlineExclamationCircle, AiOutlinePlus } from "react-icons/ai";
import userService from "../../../services/userService";
import formatService from "../../../services/formatService";
import {
  validationSchema,
  personalInfoInputs,
  physicalAddressInputs,
  mailingAddressInputs,
} from "../formEntities/personalInformationEntities";
// import statesArr from "../statesArray";
import SaveAndContinueUserMessage from "../../common/userMessages/saveAndContinueMessage";

const PersonalInformationForm = ({ shake, user, nextLink, updatePage, userData }) => {
  const navigate = useNavigate();
  const [addMailing, setAddMailing] = useState(null);
  const [physicalAddress2, setPhysicalAddress2] = useState(false);
  const [mailingAddress2, setMailingAddress2] = useState(false);

  const styles = {
    errorStyle: {
      border: "2px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
  };

  const handleValidatations = (values) => {
    const errors = {};
    if (
      values.dob &&
      values.dob !== "" &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.dob
      )
    ) {
      errors.dob = "Invalid date format.  Use MM/DD/YYYY";
    }
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address.  Use example@email.com";
    }
    if (values.ssn && !/^[0-9]+$/i.test(values.ssn)) {
      errors.ssn = "Must be all numeric values";
    }

    if (values.physicalZip && !/^[0-9]+$/i.test(values.physicalZip)) {
      errors.physicalZip = "Must be all numeric values";
    }
    if (values.mailingZip && !/^[0-9]+$/i.test(values.mailingZip)) {
      errors.mailingZip = "Must be all numeric values";
    }
    return errors;
  };

  const handleSaveAndContinue = (values, errors) => {
    if (
      !values.firstName ||
      !values.lastName ||
      !values.dob ||
      !values.email ||
      !values.ssn ||
      !values.physicalAddress1 ||
      !values.physicalCity ||
      !values.physicalState ||
      !values.physicalZip ||
      Object.keys(errors).length >= 1
    ) {
      shake();
    }
  };

  const handleFormikSubmit = async (values) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
    });
    values.ssn = values.ssn.substr(0, 3) + "-" + values.ssn.substr(3, 2) + "-" + values.ssn.substr(5);
    values.phoneNumber = values.phoneNumber.replace(/\D/g, '');
    await userService.saveUserData(user, "empInfo", values);
    userData.empInfo = values;
    navigate(`../${nextLink}`);
    updatePage();
  };

  // Merging all form inputs imported from 'inputObjects/personalInformationObjects'
  let mergedFormInputs = personalInfoInputs.concat(
    physicalAddressInputs,
    mailingAddressInputs
  );

  // Assigning merged input names as keys for initialValues
  const inputKeys = mergedFormInputs.map((input) => {
    let keys = input.name;
    return keys;
  });

  // Creating initialValues by setting input keys to each have empty string value
  const initialValues = inputKeys.reduce((acc, key) => {
    try {
      if (userData) {
        if (acc[key] !== "")
          acc[key] = userData.empInfo[key];
      }
    } catch {
      acc[key] = "";
    }

    return acc;
  }, {});

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={(values) => handleValidatations(values)}
      onSubmit={(values) => handleFormikSubmit(values)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Container>
          {/* {console.log("Values", values)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Personal Information */}
            <section>
              <h3>Personal Information</h3>
              <Row>
                {personalInfoInputs.map((input, index) => (
                  <Col sm={12} md={6} className="text-start" key={index}>
                    <Form.Group className="mb-3" controlId={input.name}>
                      <Form.Label>{input.label}</Form.Label>
                      <InputGroup>
                        {input.prependIcon && (
                          <InputGroup.Text
                            id="inputGroupPrepend"
                            style={
                              errors[input.name] &&
                              touched[input.name] &&
                              styles.inputGroupTextStyle
                            }
                          >
                            @
                          </InputGroup.Text>
                        )}
                        <Form.Control
                          type={input.type}
                          placeholder={input.placeholder}
                          name={input.name}
                          onChange={handleChange}
                          maxLength={
                            input.name === "dob"
                              ? 10
                              : input.name === "ssn"
                                ? 9
                                : null
                          }
                          value={values[input.name]}
                          style={
                            errors[input.name] && touched[input.name]
                              ? styles.errorStyle
                              : null
                          }
                          disabled={userData.submissionDate}
                          onKeyUp={input.name === "dob" ? (e) => formatService.formatFullDate(e) : null}
                        />
                        {errors[input.name] && touched[input.name] && (
                          <InputGroup.Text style={styles.inputGroupTextStyle}>
                            <AiOutlineExclamationCircle
                              style={styles.errIcon}
                            />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {errors[input.name] && touched[input.name] ? (
                        <div style={styles.fieldErrMessage}>
                          {errors[input.name]}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                ))}
              </Row>
            </section>
            <hr />

            {/* Physical Address Section  */}
            <section>
              <h3>Physical Address</h3>
              <Row>
                {physicalAddressInputs.map((input, index) => (
                  <Col sm={12} md={6} className="text-start" key={index}>
                    {input.name === "physicalAddress2" && !physicalAddress2 && (
                      <div
                        className="text-start d-flex align-items-center"
                        style={{ height: "100%" }}
                      >
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            textDecoration: "underline",
                          }}
                          onClick={() => setPhysicalAddress2(true)}
                        >
                          <AiOutlinePlus className="me-2" /> Add Address Line 2
                        </button>
                      </div>
                    )}
                    <Form.Group
                      style={
                        input.name === "physicalAddress2" && !physicalAddress2
                          ? { visibility: "hidden", display: "none" }
                          : {}
                      }
                      className="mb-3"
                      controlId={index}
                    >
                      <Form.Label>{input.label}</Form.Label>
                      <InputGroup>
                        {input.prependIcon && (
                          <InputGroup.Text
                            id="inputGroupPrepend"
                            style={
                              errors[input.name] &&
                              touched[input.name] &&
                              styles.inputGroupTextStyle
                            }
                          >
                            @
                          </InputGroup.Text>
                        )}
                        <Form.Control
                          type={input.type}
                          placeholder={input.placeholder}
                          name={input.name}
                          onChange={handleChange}
                          maxLength={input.name === "physicalZip" ? 5 : null}
                          value={values[input.name]}
                          style={
                            errors[input.name] && touched[input.name]
                              ? styles.errorStyle
                              : null
                          }
                          disabled={userData.submissionDate}
                        />
                        {errors[input.name] && touched[input.name] && (
                          <InputGroup.Text style={styles.inputGroupTextStyle}>
                            <AiOutlineExclamationCircle
                              style={styles.errIcon}
                            />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {errors[input.name] && touched[input.name] ? (
                        <div style={styles.fieldErrMessage}>
                          {errors[input.name]}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                ))}
              </Row>
            </section>

            {/* Add Mailing Section  */}
            <section>
              <Form.Group
                className="mb-3 text-start"
                controlId="form-basic-checkbox"
              >
                <Form.Check
                  type="checkbox"
                  label="Add mailing address"
                  onChange={() => setAddMailing(!addMailing)}
                />
              </Form.Group>
            </section>

            {/* Mailing Section  */}
            <section>
              {addMailing && (
                <>
                  <hr />
                  <h3>Mailing Address</h3>
                  <Row>
                    {mailingAddressInputs.map((input, index) => (
                      <Col sm={12} md={6} className="text-start" key={index}>
                        {input.name === "mailingAddress2" &&
                          !mailingAddress2 && (
                            <div
                              className="text-start d-flex align-items-center"
                              style={{ height: "100%" }}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  textDecoration: "underline",
                                }}
                                onClick={() => setMailingAddress2(true)}
                              >
                                <AiOutlinePlus className="me-2" /> Add Address
                                Line 2
                              </button>
                            </div>
                          )}
                        <Form.Group
                          style={
                            input.name === "mailingAddress2" && !mailingAddress2
                              ? { visibility: "hidden", display: "none" }
                              : {}
                          }
                          className="mb-3"
                          controlId={index}
                        >
                          <Form.Label>{input.label}</Form.Label>
                          <InputGroup>
                            {input.prependIcon && (
                              <InputGroup.Text
                                id="inputGroupPrepend"
                                style={
                                  errors[input.name] &&
                                  touched[input.name] &&
                                  styles.inputGroupTextStyle
                                }
                              >
                                @
                              </InputGroup.Text>
                            )}
                            <Form.Control
                              type={input.type}
                              placeholder={input.placeholder}
                              name={input.name}
                              onChange={handleChange}
                              maxLength={input.name === "mailingZip" ? 5 : null}
                              value={values[input.name]}
                              style={
                                errors[input.name] && touched[input.name]
                                  ? styles.errorStyle
                                  : null
                              }
                            />
                            {errors[input.name] && touched[input.name] && (
                              <InputGroup.Text
                                style={styles.inputGroupTextStyle}
                              >
                                <AiOutlineExclamationCircle
                                  style={styles.errIcon}
                                />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                          {errors[input.name] && touched[input.name] ? (
                            <div style={styles.fieldErrMessage}>
                              {errors[input.name]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </section>

            {/* Submit Section  */}
            <SaveAndContinueUserMessage messageType="save and continue" />
            <Button
              type="submit"
              onClick={() => handleSaveAndContinue(values, errors)}
            >
              Save and Continue
            </Button>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default PersonalInformationForm;
