import PersonalInformationForm from "../forms/pageForms/personalInformationForm";
import TaskComplete from "../common/taskComplete";

export default function PersonalInformation({
  user,
  userData,
  shake,
  updatePage,
}) {
  return (
    <>
      {/* {userData.empInfo.ssn ? (
        <section className="main-section">
          <h2>Personal Information</h2>
          <section className="main-section-content">
            <TaskComplete
              pageTitle="Personal Information"
              nextLink="phase2-general"
            />
          </section>
        </section>
      ) : (
        <> */}
          {/* Page Instructions Section  */}
          <section className="main-section">
            <h2>Personal Information</h2>
            <div className="main-section-content text-start">
              <p>
                Please complete the form below. After you enter all required
                information, click "Next" button to submit your information and
                continue to "Residence History"
              </p>
              <p>* Indicates required field</p>
            </div>
          </section>

          {/* Form Section  */}
          <section className="main-section">
            <h2>Personal Information Form</h2>
            <div className="main-section-content">
              <PersonalInformationForm
                updatePage={updatePage}
                shake={shake}
                nextLink="phase2-general"
                user={user}
                userData={userData}
              />
            </div>
          </section>
        </>
      // )}
    // </>
  );
}
