// import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Welcome({user, userData}) {
  const navigate = useNavigate();

  return (
    <>
      {/* Instructions  */}
      <section className="main-section">
        <h2>Welcome</h2>
        <div className="main-section-content text-start">
          <p>
            Thank you for choosing to apply for a position with
            <strong> {userData ? userData.agencyInfo ? userData.agencyInfo.name : "our Home Care Agency" : "our Home Care Agency"}.</strong>
          </p>
          <p>Let's walk through the application process together.</p>
          <p>
            Below you will find a checklist to help you get organized and
            expedite the process.
          </p>
          <p>
            There are 4 phases in the application process. Access to each phase
            will be permitted only when all previous phase requirements are met.{" "}
          </p>
          <p>
            You can navigate current phase and completed phases freely, however,
            you will not be permitted to submit your application if Phase 1 and
            Phase 2 are incomplete.
          </p>
          {/* <p>
            In the sections found below, you will be asked to verify your mobile
            device and to create an applicant account.
          </p>
          <p>
            You will not be able to continue application process without
            creating an account or verifying mobile device.{" "}
          </p> */}

          <p>
            Please contact <b>{userData.agencyInfo ? userData.agencyInfo.email: "<Error>"}</b> if you need
            application assistance.
          </p>
        </div>
      </section>

      {/* Checklist  */}
      <section className="main-section">
        <h2>Checklist</h2>
        <div className="main-section-content">
          <h3>Information</h3>
          <ul className="text-start">
            <li>
              Personal Information such as name, physical address, mailing
              address and social security number.
            </li>
            <li>Last 7 years or residence history</li>
            <li>Employment history</li>
            <li>2 References (One personal and one professional)</li>
            <li>
              Prior Education Information such as school name, location, and
              dates attended,
            </li>
          </ul>
          <h3>Documents</h3>
          <ul className="text-start">
            <li>Driver's License or Personal Identification Card</li>
            <li>
              Social Security Care or Permanent Resident Care ("Green Card")
            </li>
            <li>TB Skin Test</li>
            <li>Covid Vaccination Card</li>
            <li>
              Prior Education Information such as school name, location, and
              dates attended,
            </li>
          </ul>
          <h3>Miscellaneous</h3>
          <ul className="text-start">
            <li>Access to mobile phone/device you used to create account.</li>
          </ul>
          <Button onClick={() => navigate(`../phase1-personal-information`)}>
            Next
          </Button>
        </div>
      </section>
    </>
  );
}
