import { object, string, ref } from "yup";

const validationSchema = object().shape({
  password: string()
    .required("Password is Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"
    ),
    mobileNumber:  string()
    .required("Mobile Number is required"),
    confirmMobileNumber: string()
    .required("Mobile Number Confirmation is required")
    .oneOf([ref('mobileNumber')], 'Mobile Confirmation must match'),
    email: string()
    .required("Email is Required"),
    firstName: string()
    .required("First Name is Required"),
    lastName: string()
    .required("Last Name is Required"),
    confirmPassword: string()
    .required("Passwords Must Match")
    .oneOf([ref('password')], 'Passwords must match')
});

const signupInputs = [
  {
    name: "email",
    label: "Email *",
    type: "email",
    placeholder: "example@email.com",
  },
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    placeholder: "Enter First Name",
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: "text",
    placeholder: "Enter Last Name",
  },
  {
    name: "mobileNumber",
    label: "Mobile Number *",
    type: "text",
    placeholder: "Enter Mobile Number",
  },
  {
    name: "confirmMobileNumber",
    label: "Confirm Mobile Number *",
    type: "text",
    placeholder: "Enter Mobile Number",
  },
  {
    name: "password",
    label: "Password *",
    type: "text",
    placeholder: "Enter Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password *",
    type: "text",
    placeholder: "Enter Password",
  }
];

export { signupInputs, validationSchema };
