import React from "react";

const SaveAndContinueUserMessage = ({ ...props }) => {
  return (
    <>
      {props.messageType === "save and continue" && (
        <p className="alert alert-warning text-start">
          Clicking 'Save and Continue' button will submit all information and mark this
          task as complete. No further edits possible. Check all information for
          acuracy.
        </p>
      )}
      {props.messageType === "save" && (
        <p className="alert alert-warning text-start">
          Clicking 'Save' will submit your choice and mark {props.task} task as
          complete. No further edits possible
        </p>
      )}
    </>
  );
};

export default SaveAndContinueUserMessage;
