import React, { useEffect } from "react";
// import LoginForm from "../forms/pageForms/loginForm";
import ResetPasswordForm from "../forms/pageForms/resetPasswordForm";
import { Link } from "react-router-dom";

export default function ResetPassword({}) {
  useEffect(() => {}, []);

  const handleFormikSubmit = (values, actions) => {
    // console.log("Values: ", values);
    // console.log("Actions: ", actions);
  };

  return (
    <main className="registration">
      <section className="main-section">
        <h2>Reset Password</h2>
        <section className="main-section-content">
          <ResetPasswordForm handleFormikSubmit={handleFormikSubmit} />
          <hr />
          <section className="text-center mt-3">
            <div className="d-flex justify-content-around">
              <p>Already have an account?</p>
              <Link to="/login">LOGIN</Link>
            </div>

            <div className="d-flex justify-content-around">
              <p>Don't have an account?</p>
              <Link to="/signup/:id">SIGN UP</Link>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
}
