import { object, string } from "yup";

const validationSchema = object().shape({
  firstName: string()
    .min(2, "Names must have at least 2 characters")
    .max(100, "Names can't be longer than 100 characters")
    .required("First name is required"),
  lastName: string()
    .min(2, "Names must have at least 2 characters")
    .max(100, "Names can't be longer than 100 characters")
    .required("Last name is required"),
  dob: string()
    .min(8, "Enter birthday the format shown MM/DD/YYYY")
    .required("Birthday is required "),
  email: string().required("Email is required"),
  ssn: string().min(9, "Enter 9 numeric values").required("SSN is required"),
  physicalAddress1: string()
    .min(2, "Must be at least 2 characters")
    .required("Physical address is required"),
  physicalCity: string()
    .min(2, "Must be at least 2 characters")
    .required("Physical city is required"),
  physicalState: string()
    .max(2, "Enter 2 character State identifier")
    .required("Physical state is required")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'State can only contain letters'
      ),
  physicalZip: string()
    .min(5, "Must be at least 5 characters")
    .required("Physical zip is required"),
});

const personalInfoInputs = [
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    placeholder: "Enter First Name",
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: "text",
    placeholder: "Enter Last Name",
  },
  {
    name: "dob",
    label: "Date of Birth *",
    type: "text",
    placeholder: "MM/DD/YYYY",
  },
  {
    name: "email",
    label: "Email *",
    type: "text",
    placeholder: "example@email.com",
    prependIcon: "@",
  },
  {
    name: "ssn",
    label: "Social Security Number *",
    type: "password",
    placeholder: "#########"
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
    placeholder: "########"
  }
];

const physicalAddressInputs = [
  {
    name: "physicalAddress1",
    label: "Physical Address *",
    type: "text",
    placeholder: "Enter physical address",
  },
  {
    name: "physicalAddress2",
    label: "Physical Address 2 *",
    type: "text",
    placeholder: "Enter physical address",
  },
  {
    name: "physicalCity",
    label: "Physical City *",
    type: "text",
    placeholder: "Enter physical city",
  },
  {
    name: "physicalState",
    label: "Physical State *",
    type: "text",
    placeholder: "Enter physical state",
  },
  {
    name: "physicalZip",
    label: "Physical Zip *",
    type: "text",
    placeholder: "Enter physical zip",
  },
];

const mailingAddressInputs = [
  {
    name: "mailingAddress1",
    label: "Mailing Address",
    type: "text",
    placeholder: "Enter mailing address",
  },
  {
    name: "mailingAddress2",
    label: "Mailing Address 2",
    type: "text",
    placeholder: "Enter mailing address",
  },
  {
    name: "mailingCity",
    label: "Mailing City",
    type: "text",
    placeholder: "Enter mailing city",
  },
  {
    name: "mailingState",
    label: "Mailing State",
    type: "text",
    placeholder: "Enter mailing state",
  },
  {
    name: "mailingZip",
    label: "Mailing Zip",
    type: "text",
    placeholder: "Enter mailing zip",
  },
];

export {
  validationSchema,
  personalInfoInputs,
  physicalAddressInputs,
  mailingAddressInputs,
};
