import http from "./httpService";
import apiUrl from "../config.json";

function formatFullDate(e) {
    var key = e.keyCode || e.charCode;

    if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key == 191) {
        if (e.target.value.length === 2) {
            //Invalidates date formatting if the key pressed is Backspace or Delete
            if (key == 8 || key == 46)
                return false;

            //If User inputs two numbers then 
            if (e.target.value.substr(1, 1) !== '/')
                e.target.value += '/'
            else
                e.target.value = '0' + e.target.value.substr(0, 1) + '/'
        }

        if (e.target.value.length === 4) {
            if (key === 191)
                e.target.value = e.target.value.substr(0, e.target.value.length - 1);
        }

        if (e.target.value.length === 7) {
            if (key === 191)
                e.target.value = e.target.value.substr(0, e.target.value.length - 1);
        }

        if (e.target.value.length === 5) {
            //Invalidates date formatting if the key pressed is Backspace or Delete
            if (key == 8 || key == 46)
                return false;

            //If User inputs two numbers then 
            if (e.target.value.substr(4, 1) !== '/')
                e.target.value += '/'
            else
                e.target.value = e.target.value.substr(0, 2) + '/0' + e.target.value.substr(3, 1) + '/'
        }
    }
    else
        e.target.value = e.target.value.substr(0, e.target.value.length - 1);
}

function formatHalfDate(e) {
    var key = e.keyCode || e.charCode;

    if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key == 191) {
        if (e.target.value.length === 2) {
            //Invalidates date formatting if the key pressed is Backspace or Delete
            if (key == 8 || key == 46)
                return false;

            //If User inputs two numbers then 
            if (e.target.value.substr(1, 1) !== '/')
                e.target.value += '/'
            else
                e.target.value = '0' + e.target.value.substr(0, 1) + '/'
        }

        if (e.target.value.length === 4) {
            if (key === 191)
                e.target.value = e.target.value.substr(0, e.target.value.length - 1);
        }
    } else
        e.target.value = e.target.value.substr(0, e.target.value.length - 1);
}

const isNumericInput = (e) => {
    const key = e.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
};

const isModifierKey = (e) => {
    const key = e.keyCode;
    return (e.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (e.ctrlKey === true || e.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
};

const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
    }
};

const formatPhone = (event) => {
    if(isModifierKey(event)) {return;}

    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const areaCode = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    if(input.length > 6){event.target.value = `(${areaCode}) ${middle}-${last}`;}
    else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
    else if(input.length > 0){event.target.value = `(${areaCode}`;}
};

export default {
    formatFullDate,
    formatHalfDate,
    formatPhone,
    enforceFormat
}