import React, { useEffect, useState } from "react";
import { Button, Form, ErrorMessage } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SignUpForm from "../forms/pageForms/signupForm";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import userService from "../../services/userService";
import agencyService from "../../services/agencyService";
import Loader from "../common/loader";

export default function Signup({ setUser, setUserData, updatePage }) {
  const params = useParams();
  const navigate = useNavigate();
  const [agencyInfo, setAgencyInfo] = useState({});
  const [signupUser, setSignupUser] = useState("");
  const [signupError, setSignupError] = useState();
  const [SMSSent, setSMSSent] = useState(false);
  const [SMSCode, setSMSCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const agencyFromURL = params.id;
    const getAgencyData = async (id) => {
      const { data: agencyData } = await agencyService.getAgencyData(id);
      agencyData.companyData.agencyCode = agencyData.agencyCode;
      setAgencyInfo(agencyData);
    }

    getAgencyData(agencyFromURL);
  }, []);

  const handleFormikSubmit = async (values, actions) => {
    try {
      setIsLoading(true);
      userService.signup(
        values.email,
        values.password,
        values.firstName,
        values.lastName,
        values.mobileNumber,
        agencyInfo.companyData
      ).then(async userResponse => {
        setIsLoading(false);
        if (userResponse.data) {
          // const newEmpInfo = { firstName: values.firstName, lastName: values.lastName, email: values.email, phoneNumber: values.mobileNumber }
          // const newUserData = { ...values, agencyInfo: agencyInfo.companyData, empInfo: newEmpInfo, applicantStatus: "in-progress" }
          // await setUserData(newUserData);
          await setUser(values.email);
          updatePage();
          setSignupUser(values.email);
          setSMSSent(true);
        } else {
          setSignupError(userResponse)
        }
      })
    } catch (ex) {
      // console.log("ERROR")
    }
  };

  const handleSMSVerification = async () => {
    setIsLoading(true);
    const verificitionResponse = await userService.verifyCognito(signupUser, SMSCode);
    setIsLoading(false);
    if (verificitionResponse === "Error in Verification") {
      setSignupError("Error in Verification - Please Check Text Again");
    } else {
      navigate("../employment-application/welcome");
    }
  }

  return (
    <main className="registration">
      <section className="main-section">
        <h2>Sign Up {agencyInfo.companyData ? " - " + agencyInfo.companyData.name : ""}</h2>
        <section className="main-section-content">
          {signupError && <div style={{ color: 'red', fontWeight: "bold" }} className="text-center">{signupError}</div>}
          {isLoading ? <Loader /> :
            !SMSSent && <SignUpForm handleFormikSubmit={handleFormikSubmit} setSignupError={setSignupError} />
          }
          {SMSSent && (
            <section className="text-center mt-3">
              <Form>
                <Form.Group className="mb-3" controlId="SMSCode">
                  <Form.Label>SMS Code</Form.Label>
                  <Form.Control type="text" placeholder="123456" onChange={e => setSMSCode(e.target.value)}
                    onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} />
                </Form.Group>
                <Button type="button" onClick={() => handleSMSVerification()}>Sign Up</Button>
              </Form>
            </section>
          )}
          <hr />
          <section className="text-center mt-3">
            <div className="d-flex justify-content-around">
              <p>Already have an account?</p>
              <Link to="/login">LOGIN</Link>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
}
