import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import RequiredFieldMessage from "../../common/userMessages/requiredFieldMessage";
import userService from "../../../services/userService";
import formatService from "../../../services/formatService";
import {
  validationSchema,
  offices,
  poi,
  dayAvailabilityArr,
  timeAvailabilityArr,
  languagesArr,
} from "../formEntities/generalEntities";
import SaveAndContinueUserMessage from "../../common/userMessages/saveAndContinueMessage";

const GeneralForm = ({ shake, user, updatePage, nextLink, userData,  pullUserData }) => {
  const navigate = useNavigate();
  const [showReqFieldMessage, setShowReqFieldMessage] = useState(false);

  useEffect(() => {
    if(!userData.agencyInfo) 
      pullUserData();
  }, []);

  const styles = {
    errorStyle: {
      border: "2px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
    errText: {
      color: "red",
    },
  };

  const handleValidations = (values) => {
    const errors = {};
    if (values.daysAvailable.length === 0) {
      errors.daysAvailable = "Required";
    }

    if (values.timesAvailable.length === 0) {
      errors.timesAvailable = "Required";
    }

    if (
      values.startDate !== "" &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.startDate
      )
    ) {
      errors.startDate = "Invalid date format.  Use MM/DD/YYYY";
    }

    if (
      values.office &&
      values.poi &&
      values.daysAvailable &&
      values.timesAvailable &&
      values.validLicense &&
      values.carAndIns &&
      Object.keys(errors).length === 0
    ) {
      setShowReqFieldMessage(false);
    }
    return errors;
  };

  const handleSaveAndContinue = (values, errors) => {
    if (
      !values.gender ||
      !values.office ||
      !values.poi ||
      !values.daysAvailable ||
      !values.timesAvailable ||
      !values.validLicense ||
      !values.carAndIns ||
      Object.keys(errors).length >= 1
    ) {
      shake();
      setShowReqFieldMessage(true);
    }
  };

  const handleFormikSubmit = async (values) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
      if (key === "office") {
        values[key] = values[key].substr(values[key].length - 3)
      }
      if (key === "poi") {
        if (values[key].indexOf("PCA") > -1 || values[key].indexOf("CNA") > -1)
          values[key] = values[key].substr(values[key].length - 3)
        else if (values[key].indexOf("Nurse") > -1)
          values[key] = values[key].substr(values[key].length - 2)
        else if (values[key].indexOf("Admin") > -1)
          values[key] = "Admin";
      }
    });
    await userService.saveUserData(user, "general", values);
    userData.general = values;
    updatePage();
    navigate(`../${nextLink}`);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        gender: userData.general ? userData.general.gender ? userData.general.gender : "" : "",
        office: userData.general ? userData.general.office ? userData.general.office : "" : "",
        poi: userData.general ? userData.general.poi ? userData.general.poi : "" : "",
        daysAvailable: userData.general ? userData.general.daysAvailable ? userData.general.daysAvailable : [] : [],
        timesAvailable: userData.general ? userData.general.timesAvailable ? userData.general.timesAvailable : [] : [],
        startDate: userData.general ? userData.general.startDate ? userData.general.startDate : "" : "",
        validLicense: userData.general ? userData.general.validLicense ? userData.general.validLicense : "" : "",
        carAndIns: userData.general ? userData.general.carAndIns ? userData.general.carAndIns : "" : "",
        languages: userData.general ? userData.general.languages ? userData.general.languages : [] : [],
        skills: userData.general ? userData.general.skills ? userData.general.skills : "" : "",
      }}
      validate={(values) => handleValidations(values)}
      onSubmit={(values) => handleFormikSubmit(values)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Container>
          {/* {console.log("Errors:", errors)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Gender */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Gender</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} md={6} className="mb-3 text-start">
                  <Form.Check
                    inline
                    type="radio"
                    id="gender1"
                    label="Male"
                    name="gender"
                    value="2"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.gender === "2" ? true : null}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="gender2"
                    label="Female"
                    name="gender"
                    value="1"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.gender === "1" ? true : null}
                  />
                </Col>
              </Row>
            </section>
            {/* Resident 5 years */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Have you been a Resident of NC for the last 5 years?</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} md={6} className="mb-3 text-start">
                  <Form.Check
                    inline
                    type="radio"
                    id="res1"
                    label="Yes"
                    name="resNC"
                    value="Y"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.residencyNC === "Y" ? true : null}
                  />

                  <Form.Check
                    inline
                    type="radio"
                    id="res2"
                    label="No"
                    name="resNC"
                    value="N"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.residencyNC === "N" ? true : null}
                  />
                </Col>
              </Row>
            </section>
            {/* Offices */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Office of Interest</h3>
                <em>Required *</em>
              </div>
              <p
                className="text-start"
                style={errors.office && touched.office && styles.errText}
              >
                Select the office of the position you are applying for
              </p>
              {userData.agencyInfo ? (userData.agencyInfo.offices.map((office, index) => (
                <div className="text-start mb-0" key={office.name}>
                  <Form.Check
                    type="radio"
                    id={office.name}
                    label={office.name}
                    name="office"
                    value={office.name}
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={office.name === values.office ? true : null}
                  />

                  <ul className="office-cities">
                    {office.locationOffices.map((officeCity) => (
                      <li key={officeCity}>{officeCity}</li>
                    ))}
                  </ul>
                </div>
              ))) : (
                <h4 className = "text-danger">Please Refresh Your Browser</h4>
              )}
            </section>
            <hr />

            {/* Position of Interest  */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Position of Interest</h3>
                <em>Required *</em>
              </div>
              <p
                className="text-start"
                style={errors.poi && touched.poi && styles.errText}
              >
                Select position you are applying for
              </p>

              <div className="text-start">
                {userData.agencyInfo ? (userData.agencyInfo.poi.map((p, index) => (
                  <Form.Check
                    type="radio"
                    id={p}
                    label={p}
                    key={p}
                    className="mb-3 w-100"
                    name="poi"
                    value={p}
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={p === values.poi ? true : null}
                  />
                ))) : (
                  <h4 className = "text-danger">Please Refresh Your Browser</h4>
                )}
              </div>
            </section>
            <hr />
            {/* Availability  */}
            <section>
              <h3>Availability</h3>
              <Row>
                <Col sm={12} md={6} className="text-start mb-3">
                  <Form.Group>
                    <Form.Label
                      style={
                        errors.daysAvailable &&
                        touched.daysAvailable &&
                        styles.errText
                      }
                    >
                      <strong>Days Available</strong>
                      <br />
                      Please select all days you are available
                    </Form.Label>
                    {dayAvailabilityArr.map((day, i) => (
                      <Form.Check
                        key={day}
                        type="checkbox"
                        label={day}
                        id={day}
                        value={day}
                        name="daysAvailable"
                        onChange={handleChange}
                        disabled={userData.submissionDate}
                        checked={values.daysAvailable.indexOf(day) > -1}
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="text-start mb-3">
                  <Form.Group required>
                    <Form.Label
                      style={
                        errors.timesAvailable &&
                        touched.timesAvailable &&
                        styles.errText
                      }
                    >
                      <strong>Time Availability</strong>
                      <br />
                      Please select all times you are available
                    </Form.Label>
                    {timeAvailabilityArr.map((time, i) => (
                      <Form.Check
                        key={time}
                        type="checkbox"
                        label={time}
                        id={time}
                        value={time}
                        name="timesAvailable"
                        onChange={handleChange}
                        disabled={userData.submissionDate}
                        checked={values.timesAvailable.indexOf(time) > -1}
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="text-start mb-3">
                  <Form.Group className="mb-3" controlId="start-date">
                    <Form.Label>Start Date (Earliest Available)</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="XX/XX/XXXX"
                        name="startDate"
                        onChange={handleChange}
                        value={values.startDate}
                        style={
                          errors.startDate && touched.startDate
                            ? styles.errorStyle
                            : null
                        }
                        maxLength={10}
                        disabled={userData.submissionDate}
                        onKeyUp={(e) => formatService.formatFullDate(e)}
                      />
                      <Button variant="primary" type="button" disabled={userData.submissionDate} onClick={() => {
                        let dateNow = new Date();
                        values.startDate = dateNow.toLocaleDateString();
                        updatePage();
                      }}>
                        Available Today
                      </Button>
                      {errors.startDate && touched.startDate && (
                        <InputGroup.Text style={styles.inputGroupTextStyle}>
                          <AiOutlineExclamationCircle style={styles.errIcon} />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                    {errors.startDate && touched.startDate ? (
                      <div style={styles.fieldErrMessage}>
                        {errors.startDate}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </section>
            <hr />

            {/* Personal Transport  */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Personal Transport</h3>
                <em>Required *</em>
              </div>
              <p className="text-start">
                Please answer both personal transport questions
              </p>
              <Row>
                <Col sm={12} md={6} className="mb-3 text-start">
                  <p
                    style={{
                      color:
                        errors.validLicense && touched.validLicense && "red",
                    }}
                  >
                    Do you have a valid Driver's License?
                  </p>
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="inline-radio-1"
                    name="validLicense"
                    value="Yes"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.validLicense === "Yes" ? true : null}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    id="inline-radio-2"
                    name="validLicense"
                    value="No"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.validLicense === "No" ? true : null}
                  />
                </Col>
                <Col sm={12} md={6} className="mb-3 text-start">
                  <p
                    style={{
                      color: errors.carAndIns && touched.carAndIns && "red",
                    }}
                  >
                    Do you have your own car with valid Insurance?
                  </p>
                  <Form.Check
                    inline
                    type="radio"
                    label="Yes"
                    id="inline-radio-3"
                    name="carAndIns"
                    value="Yes"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.carAndIns === "Yes" ? true : null}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    id="inline-radio-4"
                    name="carAndIns"
                    value="No"
                    onChange={handleChange}
                    disabled={userData.submissionDate}
                    checked={values.carAndIns === "No" ? true : null}
                  />
                </Col>
              </Row>
            </section>
            <hr />

            {/* Languages/Skills  */}
            <section>
              <Row>
                <h3>Languages/Skills</h3>
                <Col sm={12} md={6} className="text-start mb-3">
                  <Form.Group>
                    <Form.Label>Select all languages that apply</Form.Label>
                    {languagesArr.map((language, i) => (
                      <Form.Check
                        key={language}
                        type="checkbox"
                        label={language}
                        id={language}
                        value={language}
                        name="languages"
                        onChange={handleChange}
                        disabled={userData.submissionDate}
                        checked={values.languages.indexOf(language) > -1}
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="text-start">
                  <Form.Group className="mb-3" controlId="skills">
                    <Form.Label>List aditional skills</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="skills"
                      onChange={handleChange}
                      disabled={userData.submissionDate}
                      value={values.skills}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </section>

            {/* Submit Section  */}
            <section>
              <RequiredFieldMessage
                handleClick={() => navigate(`../${nextLink}`)}
                show={showReqFieldMessage ? true : false}
                reqMessage="Complete all required fields before continuing"
                hasButton={true}
              />
              <SaveAndContinueUserMessage messageType="save and continue" />
              <Button
                variant="primary"
                type="submit"
                onClick={() => handleSaveAndContinue(values, errors)}
              >
                Save and Continue
              </Button>
            </section>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default GeneralForm;
