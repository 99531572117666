// import { Link } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
// import { AiOutlineUser } from "react-icons/ai";
import { signupInputs, validationSchema } from "../formEntities/signupEntitites";
import formatService from "../../../services/formatService";

const SignUpForm = ({ handleFormikSubmit, setSignupError }) => {

  const handleValidatations = (values) => {
    const errors = {};
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
      errors.email = "Invalid email address.  Use example@email.com";


    if (values.mobileNumber && values.mobileNumber.replace(/\D/g, '').length != 10)
      errors.mobileNumber = "Mobile Number Must Have 10 Numbers (ex: 919 376-1100)";

    if (values.confirmMobileNumber && values.confirmMobileNumber.replace(/\D/g, '').length != 10)
      errors.confirmMobileNumber = "Mobile Number Must Have 10 Numbers (ex: 919 376-1100)";

    if (values.confirmMobileNumber && values.mobileNumber.replace(/\D/g, '') != values.confirmMobileNumber.replace(/\D/g, ''))
      errors.confirmMobileNumber = "Mobile Number Confirmation Must Match";

    return errors;
  };

  const handleSaveAndContinue = (values, errors) => {
    if (
      !values.email ||
      !values.lastName ||
      !values.firstName ||
      !values.mobileNumber ||
      !values.confirmMobileNumber ||
      !values.password ||
      !values.confirmPassword ||
      Object.keys(errors).length >= 1
    ) {
      // shake();
      console.log(errors);
      let errorString = "";
      Object.keys(errors).map(key => {
        errorString += errors[key] + " + ";
      })
      errorString = errorString.substring(0, errorString.length-2)
      setSignupError(errorString);
    } else 
      handleFormikSubmit(values, null)
  };

  const inputKeys = signupInputs.map((input) => {
    let keys = input.name;
    return keys;
  });

  const initialValues = inputKeys.reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});

  return (
    <section>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form className="text-center" onSubmit={handleSubmit}>
            {signupInputs.map((input, index) => (
              <Form.Group
                key={input.name}
                className="mb-3 text-start"
                controlId={index}
              >
                <Form.Label>{input.label}</Form.Label>
                <InputGroup>
                  {input.prependIcon && <InputGroup.Text>@</InputGroup.Text>}
                  <Form.Control
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    onChange={handleChange}
                    value={values[input.name]}
                    onKeyUp={input.name === "mobileNumber" || input.name === "confirmMobileNumber" ? (e) => formatService.formatPhone(e) : null}
                    />
                </InputGroup>
                <ErrorMessage name={input.name} >
                  {msg => <div style={{ color: 'red', fontWeight: "bold" }}>{msg}</div>}
                </ErrorMessage>
              </Form.Group>
            ))}
            <Button type="button" onClick={() => handleSaveAndContinue(values, errors)}>Send SMS Verification</Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default SignUpForm;
