// import { Link } from "react-router-dom";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";

const LoginForm = ({ handleFormikSubmit }) => {
  return (
    <section>
      <Formik
        // validationSchema={validationSchema}
        initialValues={{ email: "", password: "" }}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form className="text-center" onSubmit={handleSubmit}>
            {/* {console.log("Values:", values)} */}
            <Form.Group className="mb-3 text-start" controlId="email">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="example@email.com"
                  name="email"
                  onChange={handleChange}
                  value={values.username}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="password">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <RiLockPasswordFill />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                />
              </InputGroup>
            </Form.Group>
            <Button type="submit">Login</Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default LoginForm;
