import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../forms/pageForms/loginForm";
import { Link } from "react-router-dom";
import userService from "../../services/userService";
import Loader from "../common/loader";

export default function Login({ setUser, setUserData, updatePage }) {
  const navigate = useNavigate();
  useEffect(() => { }, []);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormikSubmit = async (values, actions) => {

    if (values.email == "" || values.password == "") { } else {
      setIsLoading(true);
      try {
        userService.login(
          values.email,
          values.password
        ).then(res => {
          setIsLoading(false);
          if (res === "Incorrect username or password.") {
            // setLoginError(true);
            // setPasswordError(false);
            // setUserMessage("User Not Found");
          } else {
            setUser(values.username);
            updatePage();
            navigate("../employment-application/welcome");
          }
        })
      } catch (ex) {
        // setUserMessage(ex);
      }
    }
  };

  return (
    <main className="registration">
      <section className="main-section">
        <h2>Login</h2>
        <section className="main-section-content">
          {isLoading ? <Loader /> :
            <LoginForm handleFormikSubmit={handleFormikSubmit} />
          }
          <hr />
          <section className="text-center mt-3">
            <div className="d-flex justify-content-around">
              <p>Don't have an account?</p>
              <Link to="/signup/hch">SIGN UP</Link>
            </div>

            <div className="d-flex text-start mt-3">
              <Link to="/reset-password">Reset Password</Link>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
}
