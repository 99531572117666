import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export default function Auth({ children }) {
  // console.log("Auth Children: ", children);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    // console.log("Getting User")
    // using setTimeout to simulate async (auth) event
    if (localStorage.getItem("applicant")) {
      setCurrentUser({ username: localStorage.getItem("applicant") });
      navigate("/employment-application/welcome");
    } else {
      navigate("/login");
    }
  }, []);


  return children;
}
