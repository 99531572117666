import { object, string } from "yup";

const employersInputs = [
  {
    name: "employerName",
    label: "Employer Name *",
    type: "text",
    placeholder: "Enter Employer Name",
  },
  {
    name: "employerPhone",
    label: "Employer Phone *",
    type: "text",
    placeholder: "(###) ###-####",
  },
  {
    name: "startDate",
    label: "Start Date *",
    type: "text",
    placeholder: "MM/YYYY",
  },
  {
    name: "endDate",
    label: "End Date *",
    type: "text",
    placeholder: "MM/YYYY",
  },
  {
    name: "employerAddress1",
    label: "Employer Street Address *",
    type: "text",
    placeholder: "Enter Street Address",
  },
  {
    name: "employerAddress2",
    label: "Address Line 2",
    type: "text",
    placeholder: "Enter any additional address information",
  },
  {
    name: "employerCity",
    label: "Employer City *",
    type: "text",
    placeholder: "Enter Employer City",
  },
  {
    name: "employerState",
    label: "Employer State *",
    type: "text",
    placeholder: "Enter Employer State",
  },
  {
    name: "employerZip",
    label: "Employer Zip *",
    type: "text",
    placeholder: "Enter Employer Zip",
  },
  {
    name: "supervisorName",
    label: "Supervisor Name *",
    type: "text",
    placeholder: "Enter Supervisor Name",
  },
  {
    name: "jobTitle",
    label: "Job Title *",
    type: "text",
    placeholder: "Enter Job Title",
  },
  {
    name: "startRate",
    label: "Starting Hourly Rate *",
    type: "text",
    placeholder: "00.00",
  },
  {
    name: "endRate",
    label: "End Hourly Rate *",
    type: "text",
    placeholder: "00.00",
  },
  {
    name: "jobDuties",
    label: "Job Duties *",
    type: "text",
    placeholder: "Enter Job Duties",
  },
  {
    name: "reasonForLeaving",
    label: "Reason For Leaving*",
    type: "text",
    placeholder: "Enter Reason For Leaving",
  },
  // {
  //   name: "currentEmployer",
  //   label: "Current Employer",
  //   type: "radio",
  //   placeholder: "",
  // },
  // {
  //   name: "disciplinaryExplanation",
  //   label: "Disciplinary Explanation *",
  //   type: "text",
  //   placeholder: "Enter Disciplinary explanation",
  // },
];

const validationSchema = object().shape({
  employerName: string()
    .required("Address is required")
    .min(2, "Address must have at least 2 characters")
    .max(100, "Address can't be longer than 100 characters"),
  employerPhone: string().required("Phone number is required"),
  // .min(10, "Must have at least 10 characters")
  // .max(10, "Can't be longer than 10 characters"),
  startDate: string().required("Start Date values is required"),
  employerAddress1: string().required("Employer street address is required"),
  employerCity: string().required("Employer city is required"),
  employerState: string().required("Employer state is required")
  .max(2, "Enter 2 character State identifier")
  .matches(
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'State can only contain letters'
    ),
  employerZip: string().required("Employer zip is required"),
  supervisorName: string().required("Supervisor name is required"),
  jobTitle: string().required("Job title is required"),
  startRate: string().required("Starting hourly rate is required"),
  jobDuties: string().required("Job duties are required"),
  // everDisciplined: string().required("End Date values is required"),
  // disciplinaryExplanation: string().required("End Date values is required"),
});

export { employersInputs, validationSchema };
