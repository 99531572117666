import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import userService from "../../services/userService";
import RequiredFieldMessage from "../common/userMessages/requiredFieldMessage";
import TaskComplete from "../common/taskComplete";
import EducationForm from "../forms/pageForms/educationForm";
import { educationInputs } from "../forms/formEntities/educationEntities";
import SaveAndContinueUserMessage from "../common/userMessages/saveAndContinueMessage";

export default function Education({ user, userData, updatePage, shake }) {
  const navigate = useNavigate();
  const [education, setEducation] = useState([]);
  const [noEducationInfo, setNoEducationInfo] = useState(false);
  const [showPageReqsMessage, setShowPageReqsMessage] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const onModalHide = () => {
    setModalShow(false);
    setNoEducationInfo(false);
  };

  const handleNoEducation = () => {
    setNoEducationInfo(!noEducationInfo);
    setModalShow(true);
  };

  const handleSkipStep = async () => {
    navigate("../phase2-background-information");

    await userService.saveUserData(user, "education", {
      isComplete: true,
      setNoEducationInfo: noEducationInfo,
      schools: [],
    });
    userData.education = {
      isComplete: true,
      setNoEducationInfo: noEducationInfo,
      schools: [],
    };
  };

  const handlePageReqs = (data) => {
    if (data && data.length >= 1) {
      setShowPageReqsMessage(false);
      return true;
    } else {
      setShowPageReqsMessage(true);
      shake();
      return false;
    }
  };

  const handleSubmitAndContinue = async () => {
    if (handlePageReqs(education)) {
      updatePage();
      setShowPageReqsMessage(false);
      navigate("../phase2-background-information");
      await userService.saveUserData(user, "education", {
        isComplete: true,
        ...userData.education,
      });
      userData.education = {
        isComplete: true,
        ...userData.education,
      };
    } else {
      // alert("error");
    }
  };

  // Assigning input names as keys for initialValues
  const inputKeys = educationInputs.map((input) => {
    let keys = input.name;
    return keys;
  });

  // Creating initialValues by setting input keys to each have empty string value
  const initialValues = inputKeys.reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});

  const handleFormikSubmit = async (values, actions) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
    });
    let newSchoolsArr = [...education, { ...values }];
    if (noEducationInfo) {
      handleSkipStep();
    } else {
      await userService.saveUserData(user, "education", {
        noEducationInfo: noEducationInfo,
        schools: newSchoolsArr,
      });
      userData.education = {
        noEducationInfo: noEducationInfo,
        schools: newSchoolsArr,
      }
      actions.resetForm(initialValues);
      setEducation(newSchoolsArr);
      handlePageReqs(newSchoolsArr);
    }
    updatePage();
  };

  const handleSchoolDelete = async (index) => {
    education.splice(index, 1);
    setEducation(education);
    updatePage();

    if (education.length >= 1) {
      await userService.saveUserData(user, "education", {
        isComplete: true,
        noEducationInfo: noEducationInfo,
        schools: education,
      });
      userData.education = {
        isComplete: true,
        noEducationInfo: noEducationInfo,
        schools: education,
      };
    } else {
      await userService.saveUserData(user, "education", {
        isComplete: false,
        noEducationInfo: noEducationInfo,
        schools: education,
      });
      userData.education = {
        isComplete: false,
        noEducationInfo: noEducationInfo,
        schools: education,
      };
    }
  };

  useEffect(() => {
    if (userData && userData.education) {
      setEducation(userData.education.schools);
    }
  }, [userData]);

  return (
    <>
      {/* {userData.education && userData.education.isComplete ? (
        <section className="main-section">
          <h2>Education</h2>
          <section className="main-section-content">
            <TaskComplete
              pageTitle="Education"
              nextLink="phase2-background-information"
            />
          </section>
        </section>
      ) : (
        <> */}
          <Modal show={modalShow} centered>
            <ModalHeader as="span" className="alert alert-info">
              <ModalTitle as="h4">Confirmation Required</ModalTitle>
            </ModalHeader>
            <ModalBody as="section">
              <p>
                You have indicated you have no school information or have your
                GED.
              </p>
              <p>
                If this is <strong> CORRECT</strong>, and you wish to continue,
                click "Save" button.
              </p>
              <p>
                If this is
                <strong> INCORRECT</strong>, and you wish to return to the
                residence form, click the "Close" button.
              </p>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between" as="footer">
              <SaveAndContinueUserMessage messageType="save" task="education" />
              <Button onClick={handleFormikSubmit}>Save</Button>
              <Button variant="outline-primary" onClick={onModalHide}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          {/* Page Instructions  */}
          <section className="main-section">
            <h2>Education</h2>
            <section className="main-section-content text-start">
              <p>Here we will collect your education information</p>
              <p>Follow the directions to complete the form</p>

              <div className="alert alert-info" role="alert">
                <p>
                  Education Requirements: Provide all education history
                  including High School.{" "}
                </p>
                <p>
                  If you have your GED and/or no school information, click the
                  checkbox in the form section and continue with your
                  application.
                </p>
              </div>
            </section>
          </section>

          {/* Form Section  */}
          <section className="main-section">
            <h2>Education Form</h2>
            <div className="main-section-content">
              {/* Form Section - Instructions  */}
              <section className="text-start">
                <p className="text-start">
                  Complete the form below. After all information is entered,
                  click "Add" button. Continue entering information and clicking
                  add until all required information is collected.
                </p>
                <p>* Indicates required field</p>
                <p className="text-start">
                  After all desired information is entered scroll to "Education
                  Review" and review information for accuracy.
                </p>
              </section>

              {/* Form Section - Form  */}

              <EducationForm
                shake={shake}
                education={education}
                handleNoEducation={handleNoEducation}
                handleFormikSubmit={handleFormikSubmit}
                initialValues={initialValues}
                noEducationInfo={noEducationInfo}
                userData={userData}
              />
            </div>
          </section>

          {/* Review and Submit Section  */}
          {!noEducationInfo && (
            <section className="main-section">
              <h2>Education Review</h2>
              <section className="main-section-content">
                {/* Review and Submit Section -  Instructions  */}
                <section className="text-start">
                  <p>
                    Review the below information for accuracy. If the
                    information is incorrect, click the icon to delete entry.
                    Using the form above, reenter the correct inofrmation.
                  </p>
                  <p>
                    After you have reviewed all entered information for
                    accuracy, click "Next" to continue to "Background
                    Information"
                  </p>
                </section>

                {/* Review and Submit Section - Review Table  */}
                <section
                  style={{ border: "1px solid black", marginBottom: "1rem" }}
                >
                  {education.length > 0 ? (
                    <Table striped bordered hover responsive size="sm">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>City</th>
                          <th>Dates</th>
                        </tr>
                      </thead>
                      <tbody>
                        {education.map((school, index) => (
                          <tr key={index}>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => handleSchoolDelete(index)}
                                size="sm"
                                disabled={userData.submissionDate}
                              >
                                Delete
                              </Button>
                            </td>
                            <td>{school.schoolName}</td>
                            <td>{school.schoolCity}</td>
                            <td>
                              {school.fromDate} - {school.untilDate}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="mb-0">Please use the form to add schools</p>
                  )}
                </section>

                {/* Review and Submit Section - Submit  */}
                <section>
                  <RequiredFieldMessage
                    handleClick={() =>
                      navigate("../phase2-background-information")
                    }
                    show={showPageReqsMessage ? true : false}
                    reqMessage="Provide at least 1 school"
                    hasButton={true}
                  />
                  <SaveAndContinueUserMessage messageType="save and continue" />
                  <Button variant="primary" onClick={handleSubmitAndContinue}>
                    Save and Continue
                  </Button>
                </section>
              </section>
            </section>
          )}
        {/* </>
      )} */}
    </>
  );
}
