import { object, string } from "yup";

const validationSchema = object({
  nonCompete: string().required("Required"),
  nonCompeteCompany: string().when("nonCompete", {
    is: "yes",
    then: () =>
      string()
        .required("Company name is required")
        .min(2, "Must be a minimum of 2 characters")
        .max(100, "Must not exceed 100 characters"),
  }),
  nonCompeteDate: string().when("nonCompete", {
    is: "yes",
    then: () =>
      string()
        .required("Date signed is required")
        .min(7, "Must be a minimum of 7 characters"),
  }),
  workInjury: string().required("Required"),
  workInjuryDate: string().when("workInjury", {
    is: "yes",
    then: () =>
      string()
        .required("Injury Date is required")
        .min(7, "Must be a minimum of 7 characters"),
  }),
  workInjuryExplantation: string().when("workInjury", {
    is: "yes",
    then: () =>
      string()
        .required("Injury explantion is required")
        .min(2, "Must be a minimum of 2 characters")
        .max(250, "Must not exceed 1000 characters"),
  }),
  workmanComp: string().required("Required"),
  workCompInjuryDate: string().when("workmanComp", {
    is: "yes",
    then: () =>
      string()
        .required("Injury date is required")
        .min(7, "Must be a minimum of 7 characters"),
  }),
  workCompExplanation: string().when("workmanComp", {
    is: "yes",
    then: () =>
      string()
        .required("Injury explanation is required")
        .min(2, "Must be a minimum of 2 characters")
        .max(250, "Must not exceed 250 characters"),
  }),
  disability: string().required("Required"),
  disabilityCompany: string().when("disability", {
    is: "yes",
    then: () =>
      string()
        .required("Company name is required")
        .min(2, "Must be a minimum of 2 characters")
        .max(100, "Must not exceed 100 characters"),
  }),
  disabilityAmount: string().when("disability", {
    is: "yes",
    then: () =>
      string()
        .required("Disability amount is required")
        .max(10, "Must not exceed 10 characters"),
  }),
  convictions: string().required("Required"),
  convictionsExplanation: string().when("convictions", {
    is: "yes",
    then: () =>
      string()
        .required("Convictions explanation is required")
        .min(2, "Must be a minimum of 2 characters")
        .max(100, "Must not exceed 100 characters"),
  }),
});

export { validationSchema };
