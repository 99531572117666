import http from "./httpService";
import apiUrl from "../config.json";

async function getAgencyData(agencyCode) {
    const apiEndpoint = apiUrl.apiUrl + "/employee-applications/getAgencyData"
    return await http.post(apiEndpoint, { agencyCode: agencyCode });
}

export default {
    getAgencyData
}