import React, { useState } from "react";
import { Formik } from "formik";
import { Form, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  referencesInputs,
  validationSchema,
} from "../formEntities/referencesEntities";
import { AiOutlinePlus } from "react-icons/ai";
import formatService from "../../../services/formatService";

const ReferencesForm = ({ shake, handleFormikSubmit, initialValues, userData }) => {
  const [address2, setAddress2] = useState(false);

  const styles = {
    errorStyle: {
      border: "1px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
    errText: {
      color: "red",
    },
  };

  const handleAdd = (values) => {
    if (
      !values.referenceName ||
      !values.referencePhone ||
      !values.referenceEmail ||
      !values.referenceRelationship ||
      !values.referenceType
    ) {
      shake();
    }
  };

  const handleValidations = (values) => {
    let errors = {};

    if (
      values.referencePhone &&
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/i.test(values.referencePhone)
    ) {
      errors.referencePhone = "Invalid format.  Use (###) ###-####";
    }

    if (
      values.referenceEmail &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.referenceEmail)
    ) {
      errors.referenceEmail = "Invalid email address.  Use example@email.com";
    }

    if (values.referenceZip && !/^[0-9]+$/i.test(values.referenceZip)) {
      errors.referenceZip = "Must be all numeric values";
    }
    return errors;
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validate={(values) => handleValidations(values)}
      onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
    >
      {({ values, setValues, errors, touched, handleChange, handleSubmit }) => (
        <Container className="px-0">
          {/* {console.log("Values:", values, "\nErrors:", errors)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Inputs  */}
            <Container>
              <Row>
                {referencesInputs.map((input, index) => (
                  <Col
                    sm={12}
                    md={6}
                    className={
                      !address2 && input.name === "referenceAddress2" && "mb-3"
                    }
                    key={index}
                  >
                    <>
                      {input.name === "referenceAddress2" && !address2 && (
                        <div
                          className="text-start d-flex align-items-center"
                          style={{ height: "100%" }}
                        >
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              textDecoration: "underline",
                            }}
                            onClick={() => setAddress2(true)}
                          >
                            <AiOutlinePlus className="me-2" /> Add Address Line
                            2
                          </button>
                        </div>
                      )}
                      <Form.Group
                        style={
                          input.name === "referenceAddress2" && !address2
                            ? { visibility: "hidden", display: "none" }
                            : {}
                        }
                        className="mb-3 text-start"
                        controlId={index}
                      >
                        <Form.Label>{input.label}</Form.Label>
                        <InputGroup>
                          {input.prependIcon && (
                            <InputGroup.Text
                              id="inputGroupPrepend"
                              style={
                                errors[input.name] &&
                                touched[input.name] &&
                                styles.inputGroupTextStyle
                              }
                            >
                              @
                            </InputGroup.Text>
                          )}
                          <Form.Control
                            type={input.type}
                            placeholder={input.placeholder}
                            name={input.name}
                            value={values[input.name]}
                            onChange={handleChange}
                            maxLength={
                              input.name === "referenceZip"
                                ? 5
                                : input.name === "referencePhone"
                                ? 14
                                : 100
                            }
                            style={
                              errors[input.name] && touched[input.name]
                                ? styles.errorStyle
                                : null
                            }
                            disabled={userData.submissionDate}
                            onKeyUp={input.name === "referencePhone" ? (e) => formatService.formatPhone(e) : null}
                           />
                          {errors[input.name] && touched[input.name] && (
                            <InputGroup.Text style={styles.inputGroupTextStyle}>
                              <AiOutlineExclamationCircle
                                style={styles.errIcon}
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                        {errors[input.name] && touched[input.name] ? (
                          <div style={styles.fieldErrMessage}>
                            {errors[input.name]}
                          </div>
                        ) : null}
                      </Form.Group>
                    </>
                  </Col>
                ))}
                <Col>
                  <div className="text-start mb-3">
                    <p
                      style={
                        errors.referenceType &&
                        touched.referenceType &&
                        styles.errText
                      }
                    >
                      Reference Type *
                    </p>
                    <Form.Check
                      inline
                      id="personal"
                      type="radio"
                      label="Personal"
                      value="personal"
                      name="referenceType"
                      onChange={handleChange}
                      checked={
                        values.refernceType !== "" &&
                        values.referenceType === "personal"
                      }
                    />
                    <Form.Check
                      inline
                      id="professional"
                      type="radio"
                      label="Professional"
                      value="professional"
                      name="referenceType"
                      onChange={handleChange}
                      checked={
                        values.refernceType !== "" &&
                        values.referenceType === "professional"
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Container>

            <Button type="submit" onClick={() => handleAdd(values)}>
              Add
            </Button>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default ReferencesForm;
