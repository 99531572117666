import { object, string, number, date, InferType, array } from "yup";

const educationInputs = [
  {
    name: "schoolName",
    label: "School Name *",
    type: "text",
    placeholder: "Enter School Name",
  },
  {
    name: "schoolCity",
    label: "School City *",
    type: "text",
    placeholder: "Enter School City",
  },
  {
    name: "schoolState",
    label: "School State *",
    type: "text",
    placeholder: "Enter School State",
  },
  {
    name: "schoolZip",
    label: "School Zip *",
    type: "text",
    placeholder: "Enter School Zip",
  },
  {
    name: "startDate",
    label: "Start Date *",
    type: "text",
    placeholder: "MM/YYYY",
  },
  {
    name: "endDate",
    label: "End Date *",
    type: "text",
    placeholder: "MM/YYYY",
  },
];

const validationSchema = object().shape({
  schoolName: string()
    .required("School name is required")
    .min(2, "Must be a mininum of 2 characters")
    .max(100, "Character max met"),
  schoolCity: string()
    .required("School city is required")
    .min(2, "Must be a mininum of 2 characters")
    .max(100, "Character max met"),
  schoolState: string()
    .required("School state is required")
    .max(2, "Enter 2 character State identifier")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'State can only contain letters'
      ),
  schoolZip: string()
    .min(5, "Must be at least 5 characters")
    .required("School zip is required"),
  startDate: string().required("Start date is required")
});

const handleValidatations = (values) => {
  let errors = {};
  if (
    values.startDate &&
    values.startDate !== "" &&
    /((0[1-9]|1[0-2])\/[12]\d{4})/.test(
      values.startDate
    )
  ) {
    errors.startDate = "Invalid date format.  Use MM/YYYY";
  }

  if (
    values.endDate &&
    values.endDate !== "" &&
    /((0[1-9]|1[0-2])\/[12]\d{4})/.test(
      values.endDate
    )
  ) {
    errors.endDate = "Invalid date format.  Use MM/YYYY";
  }

  if (values.schoolZip && !/^[0-9]+$/i.test(values.schoolZip)) {
    errors.schoolZip = "Must be all numeric values";
  }
  return errors;
};

export { educationInputs, validationSchema, handleValidatations };
