import { object, string } from "yup";

const validationSchema = object().shape({
  address1: string()
    .required("Address is required")
    .min(2, "Address must have at least 2 characters")
    .max(100, "Address can't be longer than 100 characters"),
  city: string()
    .required("City is required")
    .min(2, "City must have at least 2 characters")
    .max(100, "City can't be longer than 100 characters")
    .required("Address is required"),
  state: string()
    .required("State is required")
    .max(2, "Enter 2 character State identifier")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'State can only contain letters'
      ),
  zip: string()
    .required("Zip is required")
    .min(5, "Must be at least 5 characters")
    .max(5),
  fromDate: string()
    .min(7, "Enter in the following format MM/YYYY")
    .max(7)
    .required("From Date is required"),
  untillDate: string()
    .min(7, "Enter in the following format MM/YYYY")
    .max(7)
    .required("Until date is required"),
});

const residenceInputs = [
  {
    name: "address1",
    label: "Address 1 *",
    type: "text",
    placeholder: "Enter Street Address",
  },
  {
    name: "address2",
    label: "Address 2",
    type: "text",
    placeholder: "Enter Street Address",
  },
  {
    name: "city",
    label: "City *",
    type: "text",
    placeholder: "Enter City",
  },
  {
    name: "state",
    label: "State *",
    type: "text",
    placeholder: "Enter State",
  },
  {
    name: "zip",
    label: "Zip *",
    type: "text",
    placeholder: "Enter Zip",
  },
  {
    name: "fromDate",
    label: "From Date *",
    type: "text",
    placeholder: "MM/YYYY",
  },
  {
    name: "untillDate",
    label: "Untill Date *",
    type: "text",
    placeholder: "MM/YYYY",
  }
];

export { residenceInputs, validationSchema };
