import http from "./httpService";
import apiUrl from "../config.json";

async function saveDocument(formData) {
    const apiEndpoint = apiUrl.apiUrl + "/employee-applications/saveDocument"
    return await http.post(apiEndpoint, formData);
}

export default {
    saveDocument
}