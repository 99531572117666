import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import RequiredFieldMessage from "../common/userMessages/requiredFieldMessage";
import TaskComplete from "../common/taskComplete";
import userService from "../../services/userService";
import EmployersForm from "../forms/pageForms/employersForm";
import { employersInputs } from "../forms/formEntities/employersEntities";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import SaveAndContinueUserMessage from "../common/userMessages/saveAndContinueMessage";
export default function Employers({
  user,
  userData,
  updatePage,
  shake,
}) {
  const navigate = useNavigate();
  const [employers, setEmployers] = useState([]);
  const [noEmployers, setNoEmployers] = useState(false);

  const [showPageReqsMessage, setShowPageReqsMessage] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const onModalHide = () => {
    setModalShow(false);
    setNoEmployers(false);
  };

  const handleNoEmployers = () => {
    setNoEmployers(!noEmployers);
    setModalShow(true);
  };

  const handleSkipStep = async () => {
    navigate("../phase2-references");

    await userService.saveUserData(user, "employment", {
      isComplete: true,
      neverEmployed: noEmployers,
      employers: [],
    });
    userData.employment = {
      isComplete: true,
      neverEmployed: noEmployers,
      employers: [],
    };
  };

  const handleEmployerDelete = async (index) => {
    employers.splice(index, 1);
    setEmployers(employers);
    updatePage();

    await userService.saveUserData(user, "employment", {
      neverEmployed: noEmployers,
      employers: employers,
    });
    userData.employement = {
      neverEmployed: noEmployers,
      employers: employers,
    }
  };

  const handlePageReqs = (data) => {
    if (data && data.length >= 1) {
      setShowPageReqsMessage(false);
      return true;
    } else {
      setShowPageReqsMessage(true);
      shake();
      return false;
    }
  };

  const handleSubmitAndContinue = async () => {
    if (handlePageReqs(employers)) {
      updatePage();
      setShowPageReqsMessage(false);
      navigate("../phase2-references");
      await userService.saveUserData(user, "employment", {
        isComplete: true,
        ...userData.employment,
      });
      userData.employment = {
        isComplete: true,
        ...userData.employment,
      };
    } else {
      // alert("error");
    }
  };

  // Assigning input names as keys for initialValues
  const inputKeys = employersInputs.map((input) => {
    let keys = input.name;
    return keys;
  });

  // Creating initialValues by setting input keys to each have empty string value
  const initialValues = inputKeys.reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});

  const handleFormikSubmit = async (values, actions) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
    });
    let newEmployerArr = [...employers, { ...values }];
    if (noEmployers) {
      handleSkipStep();
    } else {
      await userService.saveUserData(user, "employment", {
        neverEmployed: noEmployers,
        employers: newEmployerArr,
      });
      userData.employment = {
        neverEmployed: noEmployers,
        employers: newEmployerArr,
      };
      actions.resetForm(initialValues);
      setEmployers(newEmployerArr);
      handlePageReqs(newEmployerArr);
    }
    updatePage();
  };

  useEffect(() => {
    if (userData && userData.employment) {
      setEmployers(userData.employment.employers);
    }
  }, [userData]);

  return (
    <>
      {/* {userData.employment && userData.employment.isComplete ? (
        <section className="main-section">
          <h2>Employment History</h2>
          <section className="main-section-content">
            <TaskComplete
              pageTitle={"Employment History"}
              nextLink="phase2-references"
            />
          </section>
        </section>
      ) : (
        <> */}
      {/* No Employment Information Modal  */}
      <Modal show={modalShow} centered>
        <ModalHeader as="span" className="alert alert-info">
          <ModalTitle as="h4">Confirmation Required</ModalTitle>
        </ModalHeader>
        <ModalBody as="section">
          <p>You have indicated you have no employment information.</p>
          <p>
            If this is <strong> CORRECT</strong>, and you wish to continue,
            click "Save" button.
          </p>
          <p>
            If this is
            <strong> INCORRECT</strong>, and you wish to return to the
            employment form, click the "Close" button.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between" as="footer">
          <SaveAndContinueUserMessage messageType="save" />
          <Button onClick={handleFormikSubmit}>Save</Button>
          <Button variant="outline-primary" onClick={onModalHide}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <section className="main-section">
        <h2>Employment History</h2>
        <div className="main-section-content text-start">
          {/* Page Instructions  */}
          <section className="text-start">
            <p>Here we will collect your employment history.</p>

            <p>Follow the directions to complete the form</p>
            <p>
              If you have past and/or present employers, use the form below
              and add up to your most recent 3 employers.
            </p>
          </section>
          {employers && employers.length === 0 && (
            <div className="alert alert-info" role="alert">
              <p>
                Employment History Requirements: Add up to 3 employers.
              </p>
              <p>
                If you've never been employed, click the checkbox in the form
                section and skip this step.
              </p>
            </div>
          )}
        </div>
      </section>

      {/* Form Section  */}
      <section className="main-section">
        <h2>Employment Form</h2>
        <section className="main-section-content">
          {/* Form Section - Instructions  */}
          <section className="text-start">
            <p>
              Complete the form below. After you enter all required
              information, click the "Add" button.
            </p>
            <p>* Indicates required field</p>
            <p>
              After all desired information is entered scroll to "Employment
              History Review" and review information for accuracy.
            </p>
          </section>

          {/* Form Section - Form  */}
          <EmployersForm
            shake={shake}
            employers={employers}
            initialValues={initialValues}
            handleFormikSubmit={handleFormikSubmit}
            handleNoEmployers={handleNoEmployers}
            noEmployers={noEmployers}
            userData={userData}
          />
        </section>
      </section>

      {/* Review and Submit Section  */}
      {!noEmployers && (
        <section className="main-section">
          <h2>Employment History Review</h2>

          <section className="main-section-content">
            {/* Review and Submit - Instructions  */}
            <section className="text-start">
              <p>
                Review the below information for accuracy. If the
                information is incorrect, click the icon to delete entry.
                Using the form above, reenter the correct inofrmation.
              </p>
              <p>
                After you have reviewed all entered information for
                accuracy, click "Next" to continue to "References"
              </p>
            </section>

            {/* Review and Submit Section - Review Table  */}
            <section
              style={{ border: "1px solid black", marginBottom: "1rem" }}
            >
              {employers && employers.length > 0 ? (
                <Table striped bordered hover responsive size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employers.map((employer, index) => (
                      <tr key={index}>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleEmployerDelete(index)}
                            size="sm"
                            disabled={userData.submissionDate}
                          >
                            Delete
                          </Button>
                        </td>
                        <td>{employer.employerName}</td>
                        <td>{employer.employerPhone}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className="mb-0">
                  Please use the form to add pervious employers to the table
                </p>
              )}
            </section>

            {/* Review and Submit Section - Submit  */}
            <section>
              <RequiredFieldMessage
                handleClick={() => navigate("../phase2-references")}
                show={showPageReqsMessage ? true : false}
                reqMessage="Provide at least 1 employer"
                hasButton={true}
              />
              <SaveAndContinueUserMessage messageType="save and continue" />
              <Button variant="primary" onClick={handleSubmitAndContinue} >
                Save and Continue
              </Button>
            </section>
          </section>
        </section>
      )}
      {/* </>
      )} */}
    </>
  );
}
