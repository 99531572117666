import React from "react";
import { Formik } from "formik";
import { Form, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  educationInputs,
  validationSchema,
  handleValidatations,
} from "../formEntities/educationEntities";
import formatService from "../../../services/formatService";

const EducationForm = ({
  shake,
  handleNoEducation,
  noEducationInfo,
  handleFormikSubmit,
  initialValues,
  education,
  userData
}) => {
  const styles = {
    errorStyle: {
      border: "1px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
    errText: {
      color: "red",
    },
  };

  const handleAdd = (values) => {
    if (!values.schoolName) {
      shake();
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validate={(values) => handleValidatations(values)}
      onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Container className="px-0">
          {/* {console.log("Values:", values, "\nErrors:", errors)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Skip Step Checkbox */}
            {education.length === 0 && (
              <section className="text-start">
                <p>
                  If you have your GED or no school information, click the
                  checkbox below.
                </p>
                <Form.Group
                  className="skip-this-step text-start"
                  controlId="form-basic-checkbox"
                >
                  <Form.Check
                    type="checkbox"
                    label="I have my GED and/or no school information"
                    onChange={() => handleNoEducation()}
                    checked={noEducationInfo ? true : false}
                    value={noEducationInfo}
                  />
                </Form.Group>
              </section>
            )}
            <hr />

            {/* Inputs  */}
            <section>
              <Container>
                <Row>
                  {educationInputs.map((input, index) => (
                    <Col
                      sm={12}
                      md={6}
                      key={index}
                    >
                      <>
                        <Form.Group
                          className="mb-3 text-start"
                          controlId={index}
                        >
                          <Form.Label>{input.label}</Form.Label>
                          <InputGroup>
                            {input.prependIcon && (
                              <InputGroup.Text
                                id="inputGroupPrepend"
                                style={
                                  errors[input.name] &&
                                  touched[input.name] &&
                                  styles.inputGroupTextStyle
                                }
                              >
                                @
                              </InputGroup.Text>
                            )}
                            <Form.Control
                              type={input.type}
                              placeholder={input.placeholder}
                              name={input.name}
                              onChange={handleChange}
                              maxLength={
                                input.name === "startDate" ||
                                input.name === "endDate"
                                  ? 7
                                  : input.name === "schoolZip"
                                  ? 5
                                  : 100
                              }
                              value={values[input.name]}
                              style={
                                errors[input.name] && touched[input.name]
                                  ? styles.errorStyle
                                  : null
                              }
                              disabled={userData.submissionDate}
                              onKeyUp={input.name === "startDate" || input.name === "endDate" ? (e) => formatService.formatHalfDate(e) : null}
                            />
                            {errors[input.name] && touched[input.name] && (
                              <InputGroup.Text
                                style={styles.inputGroupTextStyle}
                              >
                                <AiOutlineExclamationCircle
                                  style={styles.errIcon}
                                />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                          {errors[input.name] && touched[input.name] ? (
                            <div style={styles.fieldErrMessage}>
                              {errors[input.name]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </>
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>

            <Button type="submit" onClick={() => handleAdd(values)}>
              Add
            </Button>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default EducationForm;
