import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import RequiredFieldMessage from "../common/userMessages/requiredFieldMessage";
import TaskComplete from "../common/taskComplete";
import userService from "../../services/userService";
import ResidenceForm from "../forms/pageForms/residenceForm";
import { residenceInputs } from "../forms/formEntities/residenceEntities";
// import ModalComponent from "../common/modal";
import SaveAndContinueUserMessage from "../common/userMessages/saveAndContinueMessage";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";

export default function Residences({
  user,
  userData,
  updatePage,
  shake,
}) {
  const navigate = useNavigate();
  const [residences, setResidences] = useState([]);
  const [extendedAddress, setExtendedAddress] = useState(false);
  const [showPageReqsMessage, setShowPageReqsMessage] = useState(false);
  const [minYear, setMinYear] = useState();

  const [modalShow, setModalShow] = useState(false);
  const onModalHide = () => {
    setModalShow(false);
    setExtendedAddress(false);
  };

  const handleExtendedAddress = (test) => {
    setExtendedAddress(!extendedAddress);
    setModalShow(true);
  };

  const handleSkipStep = async () => {
    navigate("../phase2-employers");

    let residence = {
      address1: userData.empInfo.physicalAddress1,
      address2: userData.empInfo.physicalAddress2,
      city: userData.empInfo.physicalCity,
      state: userData.empInfo.physicalState,
      zip: userData.empInfo.physicalZip,
      fromDate: "Extended Residence",
      tillDate: "Current",
    };
    await userService.saveUserData(user, "residenceInfo", {
      isComplete: true,
      extendedResidence: extendedAddress,
      residences: [residence],
    });
    userData.residenceInfo = {
      isComplete: true,
      extendedResidence: extendedAddress,
      residences: [residence],
    }
  };

  const handlePageReqs = (data) => {
    if (data) {
      let formYear = data.fromDate.slice(-4);
      if (parseInt(formYear) <= parseInt(minYear)) {
        setShowPageReqsMessage(false);
        return true;
      }
    } else {
      setShowPageReqsMessage(true);
      shake();
      return false;
    }
  };

  const handleSubmitAndContinue = async (event) => {
    let x;
    if (residences) {
      residences.map((res) => {
        if (handlePageReqs(res)) {
          x = res;
        } else if(res.fromDate === "Extended Residence") {
          x = res;
        }
      });
    }
    // console.log(residences);
    if (x) {
      setShowPageReqsMessage(false);
      navigate("../phase2-employers");
      userData.residenceInfo = {
        isComplete: true,
        ...userData.residenceInfo,
      };
      await userService.saveUserData(user, "residenceInfo", {
        isComplete: true,
        ...userData.residenceInfo,
      });
      updatePage();
    }

    if (residences.length <= 0) {
      shake();
      setShowPageReqsMessage(true);
    }
  };

  // Assigning merged input names as keys for initialValues
  const inputKeys = residenceInputs.map((input) => {
    let keys = input.name;
    return keys;
  });

  // Creating initialValues by setting input keys to each have empty string value
  const initialValues = inputKeys.reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});

  const handleResidenceDelete = async (e) => {
    let index = e.target.value;
    let deletedRes = residences.splice(index, 1);
    residences.splice(index, 0);
    setResidences(residences);
    userData.residenceInfo = {
      extendedResidence: extendedAddress,
      residences: residences,
    };
    await userService.saveUserData(user, "residenceInfo", {
      extendedResidence: extendedAddress,
      residences: residences,
    });
    updatePage();
  };

  const handleFormikSubmit = async (values, actions) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
    });
    if (extendedAddress) {
      handleSkipStep();
    } else {
      userData.residenceInfo = {
        extendedResidence: extendedAddress,
        residences: [...residences, { ...values }],
      };
      await userService.saveUserData(user, "residenceInfo", {
        extendedResidence: extendedAddress,
        residences: [...residences, { ...values }],
      });
      actions.resetForm(initialValues);

      if (handlePageReqs(values)) {
        setShowPageReqsMessage(false);
      }
      const newResidences = [...residences, { ...values }]
      setResidences(newResidences);
    }
    updatePage();
  };

  useEffect(() => {
    if (userData && userData.residenceInfo) {
      setResidences(userData.residenceInfo.residences);
    }
    let date = new Date();
    let currentYear = date.getFullYear();
    setMinYear(currentYear - 7);
  });

  return (
    <>
      {/* {userData.residenceInfo && userData.residenceInfo.isComplete ? (
        <section className="main-section">
          <h2>Residence History</h2>
          <section className="main-section-content">
            <TaskComplete
              pageTitle={"Residences"}
              nextLink="phase2-employers"
            />
          </section>
        </section>
      ) : (
        <> */}
      {/* Extened Residence Modal  */}
      <Modal show={modalShow} centered>
        <ModalHeader as="span" className="alert alert-info">
          <ModalTitle as="h4">Confirmation Required</ModalTitle>
        </ModalHeader>
        <ModalBody as="section">
          <p>
            You have indicated your current physical address has been your
            address for at least 7 years!
          </p>
          <p>
            If this is <strong> CORRECT</strong>, and you wish to continue,
            click "Save" button.
          </p>
          <p>
            If this is
            <strong> INCORRECT</strong>, and you wish to return to the
            residence form, click the "Close" button.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between" as="footer">
          <SaveAndContinueUserMessage messageType="save" />
          <Button onClick={handleFormikSubmit}>Save</Button>
          <Button variant="outline-primary" onClick={onModalHide}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Page Instructions   */}
      <section className="main-section">
        <h2>Residence History</h2>
        <section className="main-section-content text-start">
          <p>Here we will collect your past physical addresses.</p>

          <p>Follow the directions to complete the form</p>
          {residences.length === 0 && (
            <div className="alert alert-info" role="alert">
              <p>
                Residence History Requirements: Last 7 years of residence
                history.
              </p>
              <p>
                If you have lived at your current address for more than 7
                years, click the checkbox in the form section and skip this
                step.
              </p>
            </div>
          )}
        </section>
      </section>

      {/* Form Section  */}
      <section className="main-section">
        <h2>Residence Form</h2>
        <section className="main-section-content">
          {/* Form Section - Instructions  */}
          <section className="text-start">
            <p>
              Complete the form below. After all information is entered,
              click "Add" button. Continue entering information and clicking
              add until all required information is collected.
            </p>
            <p>* Indicates required field</p>

            <p>
              After all desired information is entered scroll to "Residence
              History Review" and review information for accuracy.
            </p>
          </section>

          {/* Form Section - Form  */}
          <ResidenceForm
            shake={shake}
            handleExtendedAddress={handleExtendedAddress}
            extendedAddress={extendedAddress}
            residences={residences}
            handleFormikSubmit={handleFormikSubmit}
            initialValues={initialValues}
            userData={userData}
            updatePage={updatePage}
          />
        </section>
      </section>

      {/* Review and Submit Section  */}
      <section className="main-section">
        <h2>Residence History Review</h2>
        <section className="main-section-content">
          {/* Review and Submit Section -  Instructions  */}
          <section className="text-start">
            <p>
              Review the below information for accuracy. If the information
              is incorrect, click the icon to delete entry. Using the form
              above, reenter the correct inofrmation.
            </p>
            <p>
              After you have reviewed all entered information for accuracy,
              click "Next" to continue to "Employment History"
            </p>
          </section>

          {/* Review and Submit Section - Review Table  */}
          <section
            style={{ border: "1px solid black", marginBottom: "1rem" }}
          >
            {residences.length > 0 ? (
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                className="text-start"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Dates</th>
                  </tr>
                </thead>
                <tbody>
                  {residences.map((address, index) => (
                    <tr key={index}>
                      <td className="align-middle text-center">
                        <Button
                          variant="danger"
                          onClick={handleResidenceDelete}
                          value={index}
                          size="sm"
                          disabled={userData.submissionDate}
                        >
                          Delete
                        </Button>
                      </td>
                      <td>
                        {address.address1}
                        <br />
                        {address.address2}
                      </td>
                      {/* <td>{address.address2}</td> */}
                      <td>{address.city}</td>
                      <td>
                        <strong>From: </strong>
                        {address.fromDate} <br />
                        <strong>Until: </strong>
                        {address.untillDate}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="mb-0">
                Please use the form to add information.
              </p>
            )}
          </section>

          {/* Review and Submit Section - Submit  */}
          <section>
            <RequiredFieldMessage
              handleClick={() => navigate("../phase2-employers")}
              show={showPageReqsMessage ? true : false}
              reqMessage={"Provide at least 7 years of residence history.  From date must be on or before " + minYear}
              hasButton={true}
            />
            <SaveAndContinueUserMessage messageType="save and continue" />
            <Button
              className="mb-3"
              variant="primary"
              onClick={handleSubmitAndContinue}
            >
              Save and Continue
            </Button>
          </section>
        </section>
      </section>
      {/* </>
      )} */}
    </>
  );
}
