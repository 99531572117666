import React, { useState, useEffect } from "react";
import userService from "../../services/userService";
import docService from "../../services/docService";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import imageCompression from "browser-image-compression";

export default function UploadDocuments({ shake, userData, updatePage, user }) {
  const [formState, setFormState] = useState({ covidVaccinated: null, tbTested: null });

  useEffect(() => {
    if (!userData.documents) {
      userData.documents = {
        documents: {}
      };
    }

    if (!userData.documents.documents) {
      userData.documents = {
        ...userData.documents,
        documents: {}
      };
    }
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name != "covidVaccinated" && name != "tbTested") {

      const uploadName = e.target.files[0].name;
      let uploadFile;

      if(uploadName.indexOf("pdf") > -1) 
        uploadFile = e.target.files[0];
      else {
        const imageFile = e.target.files[0];
  
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920
        }
        try {
          uploadFile = await imageCompression(imageFile, options);
        } catch (error) {
          console.log(error);
        }
      }

      if (userData.applicantStatus === 'synced')
        userData.applicantStatus = "newDoc";

      const formData = new FormData();
      formData.append('files', uploadFile);
      formData.append('documentRef', e.target.name);
      formData.append('userData', JSON.stringify(userData));
      const { data: docKey } = await docService.saveDocument(formData);
      setFormState({ ...formState, [e.target.name]: docKey });
      // console.log({ ...formState, [e.target.name]: docKey});
      userData.documents.documents[e.target.name] = docKey;
    } else {
      if (name === "covidVaccinated") {
        await userService.saveUserData(user, "documents.covidVaccinated", e.target.value);
        userData.documents.covidVaccinated = e.target.value;
      }

      if (name === "tbTested") {
        await userService.saveUserData(user, "documents.tbTested", e.target.value);
        userData.documents.tbTested = e.target.value;
      }

      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  };

  return (
    <>
      {/* Instructions  */}
      <section className="main-section">
        <h2>Upload Documents</h2>
        <div className="main-section-content text-start">
          <p>Here you can upload your documents.</p>
        </div>
      </section>

      {/* Form Section */}
      <section className="main-section">
        <h2>Upload Form</h2>
        <section className="main-section-content">
          {/* Form Section - Instructions  */}
          <section className="text-start mb-5">
            <p>Use the form inputs below to upload required documents.</p>
            <p>
              If TB skintest results are required for you position, and you
              have not had a TB skintest within 1 year, we will help with
              that after an offer is extended.
            </p>
            <hr className="my-5" />
          </section>
          {/* Form Section - Form  */}
          <Form noValidate>
            {/* ID*/}
            <section className="text-start">
              <div className="d-flex align-items-center">
                <h3 className="me-2">Identification</h3>
                <em>Required *</em>
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.driverLicense
                    || userData.documents.documents.stateId ? true : false : false : false}
                  variant="success"
                  className="m-1">
                  Identification Document has been Uploaded
                </Alert>
                <Alert
                  show={userData.documents ? userData.documents.documents ? !userData.documents.documents.driverLicense
                    && !userData.documents.documents.stateId ? true : false : false : false}
                  variant="danger"
                  className="m-1">
                  Identification Document Is Required
                </Alert>
              </div>
              <p>Upload one of the following</p>
              <Form.Group
                controlId="fileDriverLic"
                className="mb-3 text-start"
              >
                <Form.Label>Driver's License</Form.Label>
                <Form.Control
                  type="file"
                  name="driverLicense"
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.driverLicense ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  Drivers License has been Uploaded
                </Alert>
              </Form.Group>

              <Form.Group
                controlId="fileIdCard"
                className="mb-3 text-start"
              >
                <Form.Label>North Carolina State ID Card</Form.Label>
                <Form.Control
                  type="file"
                  name="stateId"
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.stateId ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  State ID has been Uploaded
                </Alert>
              </Form.Group>
              <hr className="my-5" />
            </section>

            {/* Birth Certificate, SSC or passport upload*/}
            <section className="text-start">
              <div className="d-flex align-items-center">
                <h3 className="me-2">Citizenship</h3>
                <em>Required *</em>
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.birthCertificate
                    || userData.documents.documents.socialSecurityCard || userData.documents.documents.passport
                    ? true : false : false : false}
                  variant="success"
                  className="m-1">
                  Citizenship Document has been Uploaded
                </Alert>
                <Alert
                  show={userData.documents ? userData.documents.documents ? !userData.documents.documents.birthCertificate
                    && !userData.documents.documents.socialSecurityCard && !userData.documents.documents.passport
                    ? true : false : false : false}
                  variant="danger"
                  className="m-1">
                  Citizenship Document is Required
                </Alert>
              </div>
              <p>Upload one of the following</p>
              <Form.Group
                controlId="fileBirthCert"
                className="mb-3 text-start"
              >
                <Form.Label>Birth Certificate</Form.Label>
                <Form.Control
                  type="file"
                  name="birthCertificate"
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.birthCertificate ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  Birth Certificate has been Uploaded
                </Alert>
              </Form.Group>

              <Form.Group controlId="fileSsc" className="mb-3 text-start">
                <Form.Label>Social Security Card</Form.Label>
                <Form.Control
                  type="file"
                  name="socialSecurityCard"
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.socialSecurityCard ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  Social Security Card has been Uploaded
                </Alert>
              </Form.Group>
              <Form.Group
                controlId="filePassport"
                className="mb-3 text-start"
              >
                <Form.Label>Passport</Form.Label>
                <Form.Control
                  type="file"
                  name="passport"
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.passport ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  Passport has been Uploaded
                </Alert>
              </Form.Group>
              <hr className="my-5" />
            </section>

            {/* Covid Vaccination  */}
            <section className="text-start">
              <div className="d-flex align-items-center">
                <h3 className="me-2">COVID Vaccination</h3>
                <em>Required *</em>
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.covidVaccine
                    ? true : false : false : false}
                  variant="success"
                  className="m-1">
                  COVID Vaccination has been Uploaded
                </Alert>
                <Alert
                  show={userData.documents ? userData.documents.documents ? !userData.documents.documents.covidVaccine
                    ? true : false : false : false}
                  variant="danger"
                  className="m-1">
                  COVID Vaccination Is Required
                </Alert>
              </div>
              {/* <strong> */}
              <p>Are you fully vaccinated against COVID-19?</p>
              {/* </strong> */}

              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  id="inline-radio-1"
                  name="covidVaccinated"
                  value="yes"
                  onChange={handleInputChange}
                  required
                  checked={formState.covidVaccinated === "yes" ? formState.covidVaccinated === "yes" : userData.documents ? userData.documents.covidVaccinated === "yes" : null}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  id="inline-radio-2"
                  name="covidVaccinated"
                  value="no"
                  onChange={handleInputChange}
                  required
                  checked={formState.covidVaccinated === "no" ? formState.covidVaccinated === "no" : userData.documents ? userData.documents.covidVaccinated === "no" : null}
                />
              </Form.Group>

              <Form.Group
                controlId="fileCovidVax"
                className="mb-3 text-start"
              >
                <Form.Label>Upload COVID Vaccine</Form.Label>
                <Form.Control
                  type="file"
                  name="covidVaccine"
                  // disabled={
                  //   formState.covidVaccinated === "yes" ? false : true
                  // }
                  // required={
                  //   formState.covidVaccinated === "no" ? false : true
                  // }
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.covidVaccine ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  COVID Vaccine Card has been Uploaded
                </Alert>
              </Form.Group>
              <p>
                ** If you are not fully vaccinated against COVID-19, we can
                assist with getting you vaccinated after you are hired.
                COVID vaccine is <strong>REQUIRED</strong> for all clinical
                positions.
              </p>
              <hr className="my-5" />
            </section>

            {/* TB Skin test  */}
            <section className="text-start">
              <div className="d-flex align-items-center">
                <h3 className="me-2">TB Skin Test</h3>
                <em>Required *</em>
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.tbTest
                    ? true : false : false : false}
                  variant="success"
                  className="m-1">
                  TB Test has been Uploaded
                </Alert>
                <Alert
                  show={userData.documents ? userData.documents.documents ? !userData.documents.documents.tbTest
                    ? true : false : false : false}
                  variant="danger"
                  className="m-1">
                  TB Test Is Required
                </Alert>
              </div>
              {/* <strong> */}
              <p>Have you had a TB skin test within 12 months?</p>
              {/* </strong> */}
              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  id="tb-test-1"
                  name="tbTested"
                  value="yes"
                  onChange={handleInputChange}
                  required
                  checked={formState.tbTested === "yes" ? formState.tbTested === "yes" : userData.documents ? userData.documents.tbTested === "yes" : null}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  id="tb-test-2"
                  name="tbTested"
                  value="no"
                  onChange={handleInputChange}
                  required
                  checked={formState.tbTested === "no" ? formState.tbTested === "no" : userData.documents ? userData.documents.tbTested === "no" : null}
                />
              </Form.Group>
              <Form.Group
                controlId="fileTbTest"
                className="mb-3 text-start"
              >
                <Form.Label>Upload TB Skin Test results</Form.Label>
                <Form.Control
                  type="file"
                  name="tbTest"
                  // disabled={formState.tbTested === "no" ? false : true}
                  // required={formState.tbTested === "yes" ? true : false}
                  onChange={handleInputChange}
                />
                <Alert
                  show={userData.documents ? userData.documents.documents ? userData.documents.documents.tbTest ? true : false : false : false}
                  variant="success"
                  className="mt-2">
                  TB Test has been Uploaded
                </Alert>
              </Form.Group>
              <p>
                ** If you are have not had a TB skin test within [12
                months?], we can assist with getting you tested after you
                are hired. TB skin tests are
                <strong> REQUIRED</strong> in for all clinical positions.
              </p>
            </section>
            {/* <Button type="submit">Submit</Button> */}
          </Form>
        </section>
      </section>
    </>
  );

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   let originalDocumentObject = userData.documents;

  //   if (!originalDocumentObject.documents)
  //     originalDocumentObject.documents = {};

  //   if (formState.driverLicense)
  //     originalDocumentObject.documents.driverLicense = formState.driverLicense;
  //   if (formState.stateId)
  //     originalDocumentObject.documents.stateId = formState.stateId;
  //   if (formState.birthCertificate)
  //     originalDocumentObject.documents.birthCertificate = formState.birthCertificate;
  //   if (formState.socialSecurityCard)
  //     originalDocumentObject.documents.socialSecurityCard = formState.socialSecurityCard;
  //   if (formState.passport)
  //     originalDocumentObject.documents.passport = formState.passport;
  //   if (formState.covidVaccination)
  //     originalDocumentObject.documents.covidVaccination = formState.covidVaccination;
  //   if (formState.tbTest)
  //     originalDocumentObject.documents.tbTest = formState.tbTest;

  //   if (formState.tbTested)
  //     originalDocumentObject.tbTested = formState.tbTested;

  //   if (formState.covidVaccinated)
  //     originalDocumentObject.covidVaccinated = formState.covidVaccinated;

  //   var formValid = formState.tbTest || formState.covidVaccinated || formState.covidVaccination || formState.tbTested || formState.passport
  //     || formState.socialSecurityCard || formState.birthCertificate || formState.stateId || formState.driverLicense;

  //   if (formValid === undefined) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     shake();
  //   } else {
  //     updatePage();
  //     userService.saveUserData(user, "documents", originalDocumentObject);
  //   }
  // };
}
