import { forwardRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router";
import NavComponent from "./nav";
import ScrollToTop from "../common/scroll-to-top";

const PrivateLayout = forwardRef((props, ref) => {
  return (
    <div className="private-layout">
      <Container fluid>
        <Row>
          <Col lg={3}>
            <NavComponent
              userData={props.userData}
              enablePhases={props.enablePhases}
            />
          </Col>
          <Col lg={9}>
            <main className="shake-me" ref={ref}>
              <ScrollToTop />
              <Outlet />
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default PrivateLayout;
