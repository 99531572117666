import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import PBLogo from "../../assets/images/pb-logo.png";
import Version from "../common/version";

const Header = (userData) => {
  
  useEffect(() => {
    // console.log(userData);
  }, []);
  return (
    <header>
      <Container
        className="text-end d-flex align-items-center justify-content-between"
        fluid
      >
        <Version />
        {userData ? userData.userData ? userData.userData.agencyInfo ? (<h3>{userData.userData.agencyInfo.name + " Application"}</h3>) : <h3 /> : <h3 /> : <h3 /> }
        <img src={PBLogo} height="25px" alt="SembraLogo" />
      </Container>
    </header>
  );
}

export default Header
