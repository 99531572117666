import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import RequiredFieldMessage from "../common/userMessages/requiredFieldMessage";
import TaskComplete from "../common/taskComplete";
import userService from "../../services/userService";
import ReferencesForm from "../forms/pageForms/referencesForm";
import { referencesInputs } from "../forms/formEntities/referencesEntities";
import SaveAndContinueUserMessage from "../common/userMessages/saveAndContinueMessage";

export default function References({ user, userData, updatePage, shake }) {
  const navigate = useNavigate();
  const [references, setReferences] = useState([]);
  const [showPageReqsMessage, setShowPageReqsMessage] = useState(false);

  const handlePageReqs = (data) => {
    if (data && data.length >= 1) {
      setShowPageReqsMessage(false);
      return true;
    } else {
      setShowPageReqsMessage(true);
      shake();
      return false;
    }
  };

  const handleSubmitAndContinue = async () => {
    if (handlePageReqs(references)) {
      setShowPageReqsMessage(false);
      updatePage();
      navigate("../phase2-education");
      await userService.saveUserData(user, "referenceInfo", {
        isComplete: true,
        ...userData.referenceInfo,
      });
      userData.referenceInfo = {
        isComplete: true,
        ...userData.referenceInfo,
      };
    } else {
      // alert("error");
    }
  };

  // Assigning input names as keys for initialValues
  const inputKeys = referencesInputs.map((input) => {
    let keys = input.name;
    return keys;
  });

  // Creating initialValues by setting input keys to each have empty string value
  const initialValues = inputKeys.reduce((acc, key) => {
    acc[key] = "";
    acc.referenceType = "";
    return acc;
  }, {});

  const handleFormikSubmit = async (values, actions) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
    });
    let newReferencesArray = [...references, { ...values }];

    await userService.saveUserData(user, "referenceInfo", {
      references: newReferencesArray,
    });
    userData.referenceInfo = {
      references: newReferencesArray,
    };
    actions.resetForm();
    actions.resetForm(initialValues);

    setReferences(newReferencesArray);
    handlePageReqs(newReferencesArray);
    updatePage();
  };

  const handleReferenceDelete = async (index) => {
    references.splice(index, 1);
    setReferences(references);
    updatePage();

    await userService.saveUserData(user, "referenceInfo", {
      references: references,
    });
    userData.referenceInfo = {
      references: references,
    };
  };

  useEffect(() => {
    if (userData.referenceInfo && userData.referenceInfo.references) {
      setReferences(userData.referenceInfo.references);
    }
  }, [userData]);

  return (
    <>
      {/* {userData.referenceInfo && userData.referenceInfo.isComplete ? (
        <section className="main-section">
          <h2>References</h2>
          <section className="main-section-content">
            <TaskComplete
              pageTitle={"References"}
              nextLink="phase2-education"
            />
          </section>
        </section>
      ) : (
        <> */}
      {/* Page Instructions  */}
      <section className="main-section">
        <h2>References</h2>
        <section className="main-section-content text-start">
          <p>Here we will collect your references</p>
          <p>
            Please provide up to 3 personal references and up to 3
            professional references.
          </p>
          {references && references.length === 0 && (
            <div className="alert alert-info" role="alert">
              References Requirements: Provide a mininum of one personal
              reference and one professional reference (2 Total). Add up to 3 of
              each.
            </div>
          )}
        </section>
      </section>

      {/* Form Section  */}
      <section className="main-section">
        <h2>References Form</h2>
        <div className="main-section-content">
          {/* Form Section - Instructions  */}
          <section className="text-start">
            <p className="text-start">
              Complete the form below. After all information is entered,
              click "Add" button. Continue entering information and clicking
              add until all required information is collected.
            </p>
            <p className="text-start">
              After all desired information is entered scroll to "Residence
              History Review" and review information for accuracy.
            </p>
            <p>* Indicates required field</p>
          </section>
          <hr />

          {/* Form Section - Form  */}
          <section>
            <ReferencesForm
              shake={shake}
              handleFormikSubmit={handleFormikSubmit}
              initialValues={initialValues}
              updatePage={updatePage}
              userData={userData}
            />
          </section>
        </div>
      </section>

      {/* Review and Submit Section  */}
      <section className="main-section">
        <h2>Reference Review</h2>
        <section className="main-section-content">
          {/* Review and Submit Section -  Instructions  */}
          <section className="text-start">
            <p>
              Review the below information for accuracy. If the information
              is incorrect, click the icon to delete entry. Using the form
              above, reenter the correct inofrmation.
            </p>
            <p>
              After you have reviewed all entered information for accuracy,
              click "Next" to continue to "Education History"
            </p>
          </section>

          {/* Review and Submit Section - Review Table  */}
          <section
            style={{ border: "1px solid black", marginBottom: "1rem" }}
          >
            {references.length > 0 ? (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {references.map((reference, index) => (
                    <tr key={index}>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => handleReferenceDelete(index)}
                          size="sm"
                          disabled={userData.submissionDate}
                        >
                          Delete
                        </Button>
                      </td>
                      <td>{reference.referenceName}</td>
                      <td>{reference.referenceEmail}</td>
                      <td>{reference.referencePhone}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="mb-0">Please use the form to add references</p>
            )}
          </section>

          {/* Review and Submit Section - Submit  */}
          <section>
            <RequiredFieldMessage
              handleClick={() => navigate("../phase2-education")}
              show={showPageReqsMessage ? true : false}
              reqMessage="Provide at least 2 references."
              hasButton={true}
            />
            <SaveAndContinueUserMessage />
            <Button variant="primary" onClick={handleSubmitAndContinue}>
              Save and Continue
            </Button>
          </section>
        </section>
      </section>
      {/* </>
      )} */}
    </>
  );
}
