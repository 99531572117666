import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  ProgressBar,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function NavComponent({ userData, enablePhases }) {
  const location = useLocation();
  const [docsRequired, setDocsRequired] = useState();

  const getActivePage = (pageTitle) => {
    if (location.pathname.includes(pageTitle)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (userData && userData.documents) {
      if (userData.documents.documents) {
        let documentCollection = userData.documents.documents;
        let totalRequiredDocs = 4;

        if (documentCollection.driverLicense || documentCollection.stateId)
          totalRequiredDocs--;
        if (documentCollection.passport || documentCollection.birthCertificate || documentCollection.socialSecurityCard)
          totalRequiredDocs--;
        if (documentCollection.tbTest)
          totalRequiredDocs--;
        if (documentCollection.covidVaccine)
          totalRequiredDocs--;
        setDocsRequired(totalRequiredDocs);
      }
    }
  });

  return (
    <aside>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg=""
        variant=""
        className="fixed flex-lg-column text-lg-start"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="flex-column" navbarScroll>
            {/* Phase 1  */}
            <section style={{ marginTop: "1rem" }}>
              <p>Phase 1</p>
              <LinkContainer to={"welcome"}>
                <Nav.Link
                  active={getActivePage("welcome")}
                  className="complete"
                >
                  Welcome
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                to={"phase1-personal-information"}
                disabled={!userData.username ? true : false}
              >
                <Nav.Link
                  active={getActivePage("personal-information")}
                  className={userData.empInfo ? "complete" : "in-progress"}
                >
                  Personal Information
                </Nav.Link>
              </LinkContainer>
            </section>

            {/* Phase 2  */}
            <section>
              <p className={enablePhases.phase2 ? "" : "phase-disabled"}>
                Phase 2
              </p>
              <LinkContainer
                to={"phase2-general"}
                disabled={enablePhases.phase2 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("general")}
                  className={userData.general ? "complete" : "in-progress"}
                >
                  General Information
                </Nav.Link>
              </LinkContainer>

              <LinkContainer
                to={"phase2-residences"}
                disabled={enablePhases.phase2 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("residences")}
                  className={
                    userData.residenceInfo
                      ? "complete"
                      : "in-progress"
                  }
                >
                  Residence History
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                to={"phase2-employers"}
                disabled={enablePhases.phase2 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("employers")}
                  className={
                    userData.employment
                      ? "complete"
                      : "in-progress"
                  }
                >
                  Employment History
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                to={"phase2-references"}
                disabled={enablePhases.phase2 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("references")}
                  className={
                    userData.referenceInfo
                      ? "complete"
                      : "in-progress"
                  }
                >
                  References
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                to={"phase2-education"}
                disabled={enablePhases.phase2 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("education")}
                  className={
                    userData.education
                      ? "complete"
                      : "in-progress"
                  }
                >
                  Education
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                to={"phase2-background-information"}
                disabled={enablePhases.phase2 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("background")}
                  className={
                    userData.backgroundInfo ? "complete" : "in-progress"
                  }
                >
                  Background Information
                </Nav.Link>
              </LinkContainer>
            </section>

            {/* Phase 3  */}
            <section>
              <p className={enablePhases.phase3 ? "" : "phase-disabled"}>
                Phase 3
              </p>
              <LinkContainer
                to={"phase3-submit-application"}
                disabled={enablePhases.phase3 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("submit")}
                  className={
                    userData.applicantStatus === "complete" || userData.applicantStatus === "newDoc" || userData.applicantStatus === "synced"
                      ? "complete"
                      : "in-progress"
                  }
                >
                  Submit Application
                </Nav.Link>
              </LinkContainer>
            </section>

            {/* Phase 4 */}
            <section>
              <p
                className={enablePhases.phase4 ? "" : "phase-disabled"}
                style={{ marginBottom: "0", fontWeight: "800" }}
              >
                Phase 4
              </p>
              <LinkContainer
                to={"phase4-upload-documents"}
                disabled={enablePhases.phase4 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("documents")}
                  className={
                    !docsRequired ? docsRequired === 0 
                      ? "complete"
                      : "in-progress": "in-progress"
                  }
                >
                  {userData.documents ? docsRequired === 0 ? "Upload Documents (Completed)" : "Upload Documents (" + (docsRequired === undefined ? 4 : docsRequired) + " Required)" : "Upload Documents"}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                to={"phase3-submit-application"}
                disabled={enablePhases.phase4 ? false : true}
              >
                <Nav.Link
                  active={getActivePage("phase3-submit-application")}
                  className={
                    userData.documents
                      ? "documents"
                      : "in-progress"
                  }
                >
                  Prerequisites
                </Nav.Link>
              </LinkContainer>
              {/* <NavDropdown
                title="Pre Requisites"
                id="nav-dropdown"
                disabled={enablePhases.phase4 ? false : true}
              >
                <NavDropdown.Item eventKey="4.1">Pre Req 1</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2">Pre Reg 2</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3">Pre Req 3</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="4.4">LMS</NavDropdown.Item>
              </NavDropdown> */}
            </section>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </aside>
  );
}
