import http from "./httpService";
import apiUrl from "../config.json";

async function createSignature(signature) {
    const apiEndpoint = apiUrl.apiUrl + "/sign/addSignature"
    return await http.post(apiEndpoint, signature);
}

async function verifySignature(signatureID) {
    const apiEndpoint = apiUrl.apiUrl + "/sign/verifySignature"
    return await http.post(apiEndpoint, {signatureID:signatureID});
}

async function sendSignatureText(messageInfo) {
    const apiEndpoint = apiUrl.apiUrl + "/bandwidth/sendSMS"
    return await http.post(apiEndpoint, messageInfo);
}

export default {
    createSignature,
    verifySignature,
    sendSignatureText
}