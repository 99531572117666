import React, { useState, useEffect, useRef } from "react";
import './App.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import userService from "./services/userService";
import 'bootstrap/dist/css/bootstrap.min.css';
import Auth from "./components/common/auth";
import Login from "./components/pages/login";
import Signup from "./components/pages/signup";
import ResetPassword from "./components/pages/resetPassword";
import PageNotFound from "./components/pages/page-not-found";
import Loader from "./components/common/loader";
import Welcome from './components/pages/welcome';
import General from './components/pages/general'
import References from './components/pages/references';
import Employers from './components/pages/employers';
import Residences from './components/pages/residences';
import Education from './components/pages/education';
import PersonalInformation from './components/pages/personalInformation';
import BackgroundInformation from './components/pages/bg-info';
import UploadDocuments from './components/pages/upload-docs'
import SubmitApplication from './components/pages/submit-application';
// import ScrollToTop from "./components/common/scroll-to-top";
import RootLayout from "./components/layout/rootLayout";
import PrivateLayout from "./components/layout/privateLayout";

function App() {
  const [pageUpdate, setPageUpdate] = useState(null);
  const [user, setUser] = useState();
  const [userData, setUserData] = useState();
  // const [userData, setUserData] = useState({ username: "lizzy@sembracare.com" });
  const [enablePhases, setEnablePhases] = useState({
    phase2: false,
    phase3: false,
    phase4: false,
  });

  const handlePhases = (data) => {
    if (data) {
      if (data.applicantStatus === "complete" || data.applicantStatus === "newDoc" || data.applicantStatus === "synced") {
        setEnablePhases({ ...enablePhases, phase2: true, phase3: true, phase4: true })
      } else if (data.general && data.residenceInfo && data.residenceInfo && data.employment && data.referenceInfo && data.referenceInfo && data.education && data.education && data.backgroundInfo) {
        setEnablePhases({ ...enablePhases, phase2: true, phase3: true })
      } else if (data.username && data.empInfo) {
        setEnablePhases({ ...enablePhases, phase2: true })
      } else {
        setEnablePhases(enablePhases);
      }
    } else {
      setEnablePhases(enablePhases);
    }
  }

  const [isLoading, setIsLoading] = useState(true);

  const pullUserData = async () => {
    let userSave;
    if (!user) {
      const savedUser = userService.getCurrentUser();
      const { data } = await userService.getUserData(savedUser);
      userSave = data;
    } else {
      if (!userData || Object.keys(userData).length === 0) {
        const { data } = await userService.getUserData(user);
        userSave = data;
      } else {
        userSave = userData;
      }
    }
    if (userSave.length === 0) {
      setUserData({});
      setIsLoading(false);
    } else {
      setUserData(userSave);
      setIsLoading(false);
      checkPrevTasks(userSave);
    }
    handlePhases(userSave);
  }

  const updatePage = () => {
    setPageUpdate(!pageUpdate)
  }

  const shake = () => {
    // "use strict";
    const shakeElement = main.current;
    shakeElement.classList.add("animate");
    setTimeout(() => {
      shakeElement.classList.remove("animate");
    }, 1000);
  };

  const [allTasksCompleted, setAllTasksCompleted] = useState(false);
  const checkPrevTasks = (data) => {
    if (
      data.empInfo &&
      data.general &&
      data.residenceInfo &&
      data.referenceInfo &&
      data.employment &&
      data.education
    ) {
      setAllTasksCompleted(true)
    }
  };

  const main = useRef();
  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<RootLayout userData={userData} pullUserData={pullUserData} />}>
      <Route element={<Login setUser={setUser} setUserData={setUserData} updatePage={updatePage} />} />
      <Route path="login" element={<Login setUser={setUser} setUserData={setUserData} updatePage={updatePage} />} />
      <Route path="signup/:id" element={<Signup setUser={setUser} setUserData={setUserData} updatePage={updatePage} />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route index element={<Auth></Auth>} />

      <Route path="employment-application" element={<Auth><PrivateLayout userData={userData} enablePhases={enablePhases} user={user} ref={main} /></Auth>}>
        <Route path="welcome" element={<Welcome user={user} userData={userData} />} />

        <Route path="phase1-personal-information" element={<PersonalInformation user={user} userData={userData}
          shake={shake} updatePage={updatePage} />} />

        <Route path="phase2-general" element={<General user={user} userData={userData} shake={shake} updatePage={updatePage} pullUserData={pullUserData} />} />

        <Route path="phase2-residences" element={<Residences user={user} userData={userData} shake={shake} updatePage={updatePage} />} />

        <Route path="phase2-employers" element={<Employers user={user} userData={userData} shake={shake} updatePage={updatePage} />} />

        <Route path="phase2-references" element={<References user={user} userData={userData} shake={shake} updatePage={updatePage} />} />

        <Route path="phase2-education" element={<Education user={user} userData={userData} shake={shake} updatePage={updatePage} />} />

        <Route path="phase2-background-information" element={<BackgroundInformation user={user} userData={userData} shake={shake} updatePage={updatePage} enablePhases={enablePhases} allTasksCompleted={allTasksCompleted} />} />

        <Route path="phase3-submit-application" element={<SubmitApplication user={user} userData={userData} shake={shake} enablePhases={enablePhases} updatePage={updatePage} />} />
        <Route path="phase4-upload-documents" element={<UploadDocuments user={user} userData={userData} shake={shake} updatePage={updatePage} />} />

      </Route>

      <Route path="*" element={<PageNotFound />} />

    </Route >
  ))

  useEffect(() => {
    pullUserData();
  }, [pageUpdate])



  return (
    <>
      {
        isLoading ? (
          <Loader />
        ) : (
          // <div className="app shakeMe">
          // <ScrollToTop />
          <RouterProvider router={router} />
          // </div>
        )
      }
    </>


  );
}

export default App;
